import "../css/proceeding.less";

import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Typography,
} from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { WalletOutlined } from "@ant-design/icons";

import ImageButton from "../../../app/common/button/ImageButton";
import DateInput from "../../../app/common/form/proposal/DateInput";
import MaskInput from "../../../app/common/form/proposal/MaskInput";
import SelectInput from "../../../app/common/form/proposal/SelectInput";
import TextInput from "../../../app/common/form/proposal/TextInput";
import HeaderTitle from "../../../app/common/header/HeaderTitle";
import { IGeneralForm } from "../../../app/models/general";
import {
  IProceedingForm,
  ProceedingFormValues,
} from "../../../app/models/Proceeding";
import { IFormError, IOptions } from "../../../app/models/shared";
import { ITaxData } from "../../../app/models/taxdata";
import { useStore } from "../../../app/stores/store";
import alerts from "../../../app/util/alerts";
import {
  ageDesciptor,
  calculateBirthdate,
  formItemLayout,
} from "../../../app/util/utils";
import views from "../../../app/util/view";
import Concidencias from "./Concidencias";
import DatosFiscalesForm from "./DatosFiscalesForm";
import ProceedingAppointments from "./ProceedingAppointments";
import ProceedingObservations from "./ProceedingObservations";
import ProceedingQuotations from "./ProceedingQuotations";
import ProceedingRequests from "./ProceedingRequests";

const { Text, Title, Link } = Typography;
const { Option } = Select;

type ProceedingFormProps = {
  id: string;
};

const ProceedingForm: FC<ProceedingFormProps> = ({ id }) => {
  const navigate = useNavigate();
  const {
    modalStore,
    procedingStore,
    quotationStore,
    appointmentStore,
    requestStore,
    locationStore,
    optionStore,
    profileStore,
    drawerStore,
    generalStore,
  } = useStore();
  const { openDrawer } = drawerStore;
  const { generalFilter } = generalStore;
  const { scopes: quotationScopes } = quotationStore;
  const { scopes: appointmentScopes } = appointmentStore;
  const { scopes: requestScopes } = requestStore;
  const {
    scopes,
    expedientes,
    tax,
    getById,
    update,
    create,
    coincidencias,
    getnow,
    setTax,
    clearTax,
    activateWallet,
  } = procedingStore;
  const { profile } = profileStore;
  const { BranchOptions, getBranchOptions } = optionStore;

  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [readonly, setReadonly] = useState(
    searchParams.get("mode") === "readonly" || (!!id && !scopes.modificar)
  );
  const [form] = Form.useForm<IProceedingForm>();
  const [values, setValues] = useState<IProceedingForm>(
    new ProceedingFormValues()
  );
  const [errors, setErrors] = useState<IFormError[]>([]);
  const { getColoniesByZipCode } = locationStore;
  const { openModal, closeModal } = modalStore;
  const [colonies, setColonies] = useState<IOptions[]>([]);
  const [ageType, setAgeType] = useState<"days" | "months" | "years">("years");

  const goBack = (samePage: boolean) => {
    searchParams.delete("mode");
    setSearchParams(searchParams);
    if (samePage) {
      navigate(`/${views.proceeding}?${searchParams}`);
    }
    clearTax();
    closeModal();
  };

  const clearLocation = () => {
    form.setFieldsValue({
      estado: undefined,
      municipio: undefined,
      colonia: undefined,
    });
    setColonies([]);
  };

  useEffect(() => {
    const readExpediente = async (id: string) => {
      setLoading(true);
      let expediente = await getById(id);
      if (!expediente) return;

      if (expediente && expediente.cp) {
        const location = await getColoniesByZipCode(expediente.cp);
        if (location) {
          setColonies(
            location.colonias.map((x) => ({
              value: x.id,
              label: x.nombre,
            }))
          );
        } else {
          clearLocation();
        }
      }

      if (expediente.fechaNacimiento) {
        // prettier-ignore
        expediente.fechaNacimiento = moment(expediente.fechaNacimiento).utcOffset(0, true);
      }

      form.setFieldsValue({
        ...expediente,
      });

      const birthdate = expediente.fechaNacimiento;
      // prettier-ignore
      const ageDescription = !birthdate ? undefined : ageDesciptor(birthdate as moment.Moment);
      if (ageDescription) {
        form.setFieldValue("edad", ageDescription[0]);
        setAgeType(ageDescription[1]);
      } else {
        form.setFieldValue("edad", undefined);
      }

      setTax(expediente?.taxData!);
      setValues(expediente!);
      setLoading(false);
    };

    if (id) {
      readExpediente(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({ sucursal: profile?.sucursal });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const readData = async (search: IGeneralForm) => {
      await getnow(search);
    };

    readData(generalFilter);
  }, [generalFilter, getnow]);

  useEffect(() => {
    const readData = async (_search: IGeneralForm) => {
      if (BranchOptions.length === 0) await getBranchOptions();
    };

    readData(generalFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBranchOptions]);

  const setEditMode = () => {
    navigate(`/${views.proceeding}/${id}?${searchParams}&mode=edit`);
    setReadonly(!!id && !scopes.modificar);
  };

  const onValuesChange = async (changedValues: any) => {
    setErrors([]);
    const field = Object.keys(changedValues)[0];
    if (field === "edad") {
      const age = changedValues[field];
      if (age || age === 0) {
        const birthdate = calculateBirthdate(age, ageType);
        form.setFieldValue("fechaNacimiento", birthdate);
      } else {
        form.setFieldValue("fechaNacimiento", undefined);
      }
    }
    if (field === "fechaNacimiento") {
      const birthdate = changedValues[field];
      const ageDescription = !birthdate ? undefined : ageDesciptor(birthdate);
      if (ageDescription) {
        form.setFieldValue("edad", ageDescription[0]);
        setAgeType(ageDescription[1]);
      } else {
        form.setFieldValue("edad", undefined);
      }
    }
    if (field === "cp") {
      const zipCode = changedValues[field] as string;

      if (zipCode && zipCode.trim().length === 5) {
        const location = await getColoniesByZipCode(zipCode);
        if (location) {
          form.setFieldsValue({
            estado: location.estado,
            municipio: location.ciudad,
          });
          setColonies(
            location.colonias.map((x) => ({
              value: x.id,
              label: x.nombre,
            }))
          );
        } else {
          clearLocation();
        }
      } else {
        clearLocation();
      }
    }
  };

  const setPage = (page: number) => {
    const priceList = expedientes[page - 1];
    navigate(`/${views.proceeding}/${priceList.id}?${searchParams}`);
  };
  const getPage = (id: string) => {
    return expedientes.findIndex((x) => x.id === id) + 1;
  };

  const activarMonedero = async () => {
    setLoading(true);

    if (values.id) {
      await activateWallet(values.id);
      values.hasWallet = true;
    }

    setLoading(false);
  };

  const onFinish = async (newValues: IProceedingForm) => {
    setLoading(true);
    var coincidencia = await coincidencias(newValues);
    const toUpdate = { ...toJS(values), ...newValues };
    let success = false;
    let isUpdated = false;

    if (!toUpdate.nombre || !toUpdate.apellido || !toUpdate.sexo) {
      alerts.warning("El nombre y sexo no pueden estar vacíos");
      return;
    }

    const currentDate = moment();
    const ageInYears = currentDate.diff(toUpdate.fechaNacimiento, "years");
    toUpdate.edad = ageInYears;
    // prettier-ignore
    toUpdate.fechaNacimiento = (toUpdate.fechaNacimiento as moment.Moment).utcOffset(0, true);

    if (coincidencia.length > 0 && !toUpdate.id!) {
      openModal({
        title: "Se encuentran coincidencias con los siguientes expedientes",
        body: (
          <Concidencias
            handle={async () => {
              setLoading(true);
              let taxdata: ITaxData[] = [];

              if (tax) {
                for (let element of tax) {
                  if (!element.id || element.id.startsWith("tempId")) {
                    delete element.id;
                    taxdata.push(element);
                  } else {
                    taxdata.push(element);
                  }
                }
              }

              toUpdate.taxData = taxdata;
              let record = "";
              if (toUpdate.id) {
                success = await update(toUpdate);
                isUpdated = true;
              } else {
                record = (await create(toUpdate)) as string;
                success = record ? true : false;
                isUpdated = false;
              }

              setLoading(false);

              if (success) {
                if (!isUpdated)
                  navigate(
                    `/${views.proceeding}/${record}?${searchParams}&mode=readonly`
                  );
                else
                  navigate(
                    `/${views.proceeding}/${toUpdate.id}?${searchParams}&mode=edit`
                  );
              }
            }}
            expedientes={coincidencia}
            handleclose={async () => {
              setReadonly(true);
              setLoading(false);
            }}
            printing={false}
          ></Concidencias>
        ),

        closable: true,
        width: "55%",
        onClose() {
          setLoading(false);
        },
      });
    } else {
      let taxdata: ITaxData[] = [];

      for (let element of tax) {
        if (!element.id || element.id.startsWith("tempId")) {
          delete element.id;
          taxdata.push(element);
        } else {
          taxdata.push(element);
        }
      }

      toUpdate.taxData = taxdata;
      let record = "";
      if (toUpdate.id) {
        success = await update(toUpdate);
        isUpdated = true;
      } else {
        record = (await create(toUpdate)) as string;
        success = record ? true : false;
        isUpdated = false;
      }

      if (success) {
        goBack(false);
        if (!isUpdated) {
          navigate(
            `/${views.proceeding}/${record}?${searchParams}&mode=readonly`
          );
        } else {
          navigate(
            `/${views.proceeding}/${toUpdate.id}?${searchParams}&mode=edit`
          );
        }
      }

      setLoading(false);
    }
  };

  const openWallet = async () => {
    var patient = await getById(id);

    openDrawer({
      title: (
        <HeaderTitle
          title="Monedero Electrónico"
          icon={<WalletOutlined className="header-title-icon" />}
        />
      ),
      body: (
        <Card className="record-wallet">
          <Text className="wallet-title">Balance de puntos</Text>
          <Title className="wallet-text" level={4}>
            {patient?.wallet}
          </Title>
        </Card>
      ),
    });
  };

  const onChangeAgeType = (type: "days" | "months" | "years") => {
    let age = form.getFieldValue("edad");
    setAgeType(type);
    if (age || age === 0) {
      // prettier-ignore
      age = type === "days" && age > 30 ? 30 : type === "months" && age > 11 ? 11 : age;
      const birthdate = calculateBirthdate(age, type);
      form.setFieldValue("edad", age);
      form.setFieldValue("fechaNacimiento", birthdate);
    } else {
      form.setFieldValue("fechaNacimiento", undefined);
    }
  };

  const ageTypeSelector = (
    <Select
      disabled={readonly}
      value={ageType}
      className="select-after"
      onChange={onChangeAgeType}
    >
      <Option value="days">días</Option>
      <Option value="months">meses</Option>
      <Option value="years">años</Option>
    </Select>
  );

  const appointmentItems = [
    {
      key: "lab",
      label: (
        <Link
          onClick={() => {
            navigate(`/appointments/new?type=L&exp=${id}`);
          }}
        >
          Cita en laboratorio
        </Link>
      ),
    },
    {
      key: "dom",
      label: (
        <Link
          onClick={() => {
            navigate(`/appointments/new?type=D&exp=${id}`);
          }}
        >
          Cita a domicilio
        </Link>
      ),
    },
  ];

  return (
    <Spin spinning={loading} tip={"Cargando..."}>
      <Row style={{ marginBottom: 24 }}>
        {!!id && (
          <Col md={12} sm={24} xs={12} style={{ textAlign: "left" }}>
            <Pagination
              simple
              key="pagination"
              size="small"
              total={expedientes?.length ?? 0}
              pageSize={1}
              current={getPage(id)}
              onChange={setPage}
              showSizeChanger={false}
            />
          </Col>
        )}
        {!readonly && (
          <Col md={id ? 12 : 24} sm={24} xs={12} style={{ textAlign: "right" }}>
            <Button onClick={() => goBack(true)}>Cancelar</Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                form.submit();
              }}
            >
              Guardar
            </Button>
          </Col>
        )}
        {readonly && scopes.modificar && (
          <Col md={12} sm={24} xs={12} style={{ textAlign: "right" }}>
            <ImageButton
              key="edit"
              title="Editar"
              image="editar"
              onClick={setEditMode}
            />
          </Col>
        )}
      </Row>
      <Form<IProceedingForm>
        {...formItemLayout}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        form={form}
        size="small"
        onFinishFailed={({ errorFields }) => {
          const errors = errorFields.map((x) => ({
            name: x.name[0].toString(),
            errors: x.errors,
          }));
          setErrors(errors);
        }}
      >
        <Row gutter={[0, 12]} justify="space-between">
          <Col span={12}>
            <Form.Item
              label="Nombre"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              className="no-error-text"
              help=""
              required
            >
              <Input.Group>
                <Row gutter={8}>
                  <Col span={12}>
                    <TextInput
                      formProps={{
                        name: "nombre",
                        label: "Nombre(s)",
                        noStyle: true,
                      }}
                      max={500}
                      showLabel
                      readonly={readonly}
                      required
                    />
                  </Col>
                  <Col span={12}>
                    <TextInput
                      formProps={{
                        name: "apellido",
                        label: "Apellido(s)",
                        noStyle: true,
                      }}
                      max={500}
                      showLabel
                      readonly={readonly}
                      required
                    />
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <TextInput
              formProps={{
                name: "correo",
                label: "E-Mail",
                labelCol: { span: 6 },
                wrapperCol: { span: 18 },
              }}
              type="email"
              max={100}
              errors={errors.find((x) => x.name === "correo")?.errors}
              readonly={readonly}
            />
          </Col>
          <Col span={4}>
            <TextInput
              formProps={{
                name: "expediente",
                label: "Exp",
              }}
              max={500}
              // readonly={readonly}
              readonly
            />
          </Col>
          <Col span={4}>
            <SelectInput
              formProps={{
                name: "sexo",
                label: "Sexo",
                labelCol: { span: 12 },
                wrapperCol: { span: 12 },
              }}
              options={[
                { label: "F", value: "F" },
                { label: "M", value: "M" },
              ]}
              readonly={readonly}
              required
            />
          </Col>
          <Col span={8}>
            <DateInput
              formProps={{
                name: "fechaNacimiento",
                label: "Fecha Nacimiento",
                labelCol: { span: 12 },
                wrapperCol: { span: 12 },
              }}
              disableAfterDates={true}
              readonly={readonly}
              required
            />
          </Col>
          <Col span={4}>
            <Form.Item
              name="edad"
              label="Edad"
              help=""
              className="no-error-text"
              rules={[
                {
                  required: true,
                },
                {
                  type: "number",
                  min: 0,
                },
                {
                  type: "number",
                  max:
                    ageType === "days" ? 30 : ageType === "months" ? 11 : 130,
                },
              ]}
            >
              <InputNumber<number>
                disabled={readonly}
                style={{ width: "100%" }}
                precision={0}
                step={1}
                min={0}
                addonAfter={ageTypeSelector}
                placeholder="Edad"
              />
            </Form.Item>
            {/* <TextInput
                formProps={{
                  name: "edad",
                  label: "Edad",
                  labelCol: { span: 12 },
                  wrapperCol: { span: 12 },
                }}
                max={3}
                suffix="años"
                readonly={readonly}
                required
              /> */}
          </Col>
          <Col span={8}>
            <Form.Item
              label="Contacto"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              help=""
              className="no-error-text"
            >
              <Input.Group>
                <Row gutter={8}>
                  <Col span={12}>
                    <MaskInput
                      formProps={{
                        name: "telefono",
                        label: "Teléfono",
                        noStyle: true,
                      }}
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                      ]}
                      validator={(_, value: any) => {
                        if (!value || value.indexOf("_") === -1) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "El campo debe contener 10 dígitos"
                        );
                      }}
                      readonly={readonly}
                    />
                  </Col>
                  <Col span={12}>
                    <MaskInput
                      formProps={{
                        name: "celular",
                        label: "Celular",
                      }}
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                      ]}
                      validator={(_, value: any) => {
                        if (!value || value.indexOf("_") === -1) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "El campo debe contener 10 dígitos"
                        );
                      }}
                      readonly={readonly}
                    />
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Dirección"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 22 }}
              help=""
              className="no-error-text"
            >
              <Input.Group>
                <Row gutter={8}>
                  <Col span={2}>
                    <TextInput
                      formProps={{
                        name: "cp",
                        label: "CP",
                        noStyle: true,
                      }}
                      max={5}
                      min={5}
                      onChange={(e) => {
                        let valu = e.target.value;
                        if (!Number(valu)) {
                          form.setFieldValue(
                            "cp",
                            valu.substring(0, valu.length - 1)
                          );

                          return;
                        }
                        form.setFieldValue("cp", valu);
                      }}
                      showLabel
                      readonly={readonly}
                      errors={errors.find((x) => x.name === "cp")?.errors}
                    />
                  </Col>
                  <Col span={4}>
                    <TextInput
                      formProps={{
                        name: "estado",
                        label: "Estado",
                        noStyle: true,
                      }}
                      max={500}
                      showLabel
                      readonly={readonly}
                    />
                  </Col>
                  <Col span={5}>
                    <TextInput
                      formProps={{
                        name: "municipio",
                        label: "Municipio",
                        noStyle: true,
                      }}
                      max={500}
                      showLabel
                      readonly={readonly}
                    />
                  </Col>
                  <Col span={6}>
                    <SelectInput
                      formProps={{
                        name: "colonia",
                        label: "Colonia",
                        noStyle: true,
                      }}
                      showLabel
                      options={colonies}
                      readonly={readonly}
                    />
                  </Col>
                  <Col span={7}>
                    <TextInput
                      formProps={{
                        name: "calle",
                        label: "Calle",
                        noStyle: true,
                      }}
                      max={500}
                      showLabel
                      readonly={readonly}
                    />
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={12} style={{ textAlign: "left" }}>
            <Space>
              {requestScopes.crear && (
                <Button
                  onClick={() => {
                    navigate(`/requests/${id}`);
                  }}
                  type="primary"
                  disabled={!id}
                >
                  Agregar solicitud
                </Button>
              )}
              {quotationScopes.crear && (
                <Button
                  onClick={() => {
                    navigate(`/cotizacion/new?&mode=edit&exp=${id}`);
                  }}
                  type="primary"
                  disabled={!id}
                >
                  Agregar cotización
                </Button>
              )}
              {appointmentScopes.crear && (
                <Dropdown.Button
                  type="primary"
                  disabled={!id}
                  size="small"
                  menu={{
                    items: appointmentItems,
                  }}
                  placement="bottomRight"
                  arrow
                >
                  Agregar cita
                </Dropdown.Button>
              )}
              {(scopes.modificar || values?.hasWallet) && (
                <Button
                  onClick={() => {
                    values?.hasWallet ? openWallet() : activarMonedero();
                  }}
                  type="primary"
                  disabled={!id}
                >
                  {values?.hasWallet ? "Ver monedero" : "Activar monedero"}
                </Button>
              )}
            </Space>
          </Col>
          <Col span={6} style={{ textAlign: "end" }}>
            {!!id && (
              <Button
                onClick={() =>
                  openModal({
                    title: "Seleccionar o Ingresar Datos Fiscales",
                    body: <DatosFiscalesForm local recordId={id} />,
                    width: 900,
                  })
                }
                style={{
                  backgroundColor: "#6EAA46",
                  color: "white",
                  borderColor: "#6EAA46",
                }}
              >
                Datos Fiscales
              </Button>
            )}
          </Col>
          <Col span={6}>
            <SelectInput
              formProps={{
                name: "sucursal",
                label: "Sucursal",
                labelCol: { span: 6 },
                wrapperCol: { span: 18 },
              }}
              options={BranchOptions}
              required
              readonly={readonly}
            />
          </Col>
        </Row>
      </Form>
      {id && (
        <Row style={{ paddingTop: 10 }}>
          <Col span={24}>
            <Tabs
              items={[
                {
                  label: "Solicitudes",
                  key: "1",
                  children: (
                    <ProceedingRequests loading={loading} recordId={id} />
                  ),
                },
                {
                  label: "Presupuestos",
                  key: "2",
                  children: (
                    <ProceedingQuotations
                      loading={loading}
                      readonly={readonly}
                      recordId={id}
                    />
                  ),
                },
                {
                  label: "Citas",
                  key: "3",
                  children: (
                    <ProceedingAppointments
                      loading={loading}
                      readonly={readonly}
                      recordId={id}
                    />
                  ),
                },
                {
                  label: "Observaciones",
                  key: "4",
                  children: (
                    <>
                      <ProceedingObservations
                        proceedingId={values.id}
                        observaciones={values.observaciones!}
                      />
                    </>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      )}
    </Spin>
  );
};

export default observer(ProceedingForm);
