import { useEffect, useState } from "react";
import {
  IClinicResultCaptureForm,
  IResultPathological,
} from "../../../app/models/clinicResults";
import { useStore } from "../../../app/stores/store";
import { Space, Spin, Table, Typography } from "antd";
import { IColumns } from "../../../app/common/table/utils";
import { parameters } from "../../../app/util/catalogs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw } from "draft-js";

const { Text } = Typography;

type Props = {
  requestStudyId: number;
  type: string;
};

const ClinicalResultsStudy = ({ requestStudyId, type }: Props) => {
  const { clinicResultsStore } = useStore();
  const { getLabResultsByStudyId, getResultPathological } = clinicResultsStore;

  const [loading, setLoading] = useState(false);
  const [resultsLab, setResultsLab] = useState<IClinicResultCaptureForm[]>([]);
  const [resultsPat, setResultsPat] = useState<IResultPathological>();

  const [editorMacroscopica, setEditorMacroscopica] = useState<any>(
    EditorState.createEmpty()
  );
  const [editorMicroscopica, setEditorMicroscopica] = useState<any>(
    EditorState.createEmpty()
  );
  const [editorDiagnostico, setEditorDiagnostico] = useState<any>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    const getResults = async () => {
      if (type === "LABORATORIO") {
        setLoading(true);
        const results = await getLabResultsByStudyId(requestStudyId);
        setLoading(false);
        setResultsLab(results.filter((x) => x.nombre));
      }
      if (type === "PATOLOGIA") {
        setLoading(true);
        const results = await getResultPathological(requestStudyId);
        setLoading(false);
        if (!results) return;
        setResultsPat(results);
        setEditorMacroscopica(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(results.descripcionMacroscopica))
          )
        );
        setEditorMicroscopica(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(results.descripcionMicroscopica))
          )
        );
        setEditorDiagnostico(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(results.diagnostico))
          )
        );
      }
    };

    getResults();
  }, [getLabResultsByStudyId, getResultPathological, requestStudyId, type]);

  return type === "LABORATORIO" ? (
    <Table<IClinicResultCaptureForm>
      loading={loading}
      size="small"
      style={{ whiteSpace: "pre" }}
      rowKey={(record) => record.id!}
      columns={columns}
      dataSource={resultsLab}
      pagination={false}
      scroll={{ y: 500 }}
    />
  ) : type === "PATOLOGIA" ? (
    <Spin spinning={loading}>
      <Space direction="vertical" size={"middle"} style={{ display: "flex" }}>
        <div>
          <Text key="expediente">
            Muestra recibida: {resultsPat?.muestraRecibida}
          </Text>
        </div>
        <div>
          <Text key="expediente">DESCRIPCIÓN MACROSCÓPICA</Text>
          <Editor
            editorState={editorMacroscopica}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onEditorStateChange={setEditorMacroscopica}
            readOnly={true}
            toolbarHidden
          />
        </div>
        <div>
          <Text key="expediente">DESCRIPCIÓN MICROSCÓPICA</Text>
          <Editor
            editorState={editorMicroscopica}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onEditorStateChange={setEditorMicroscopica}
            readOnly={true}
            toolbarHidden
          />
        </div>
        <div>
          <Text key="expediente">DIAGNÓSTICO</Text>
          <Editor
            editorState={editorDiagnostico}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onEditorStateChange={setEditorDiagnostico}
            readOnly={true}
            toolbarHidden
          />
        </div>
      </Space>
    </Spin>
  ) : (
    <></>
  );
};

export default ClinicalResultsStudy;

const getIsOutOfRange = (item: IClinicResultCaptureForm) =>
  item.valorInicial != null &&
  item.valorFinal != null &&
  item.resultado != null &&
  (item.resultado < item.valorInicial || item.resultado > item.valorFinal);

const sharedAllSpaceOnCell = (item: IClinicResultCaptureForm) => {
  if (item.tipoValorId === parameters.valueType.etiqueta) {
    return { colSpan: 4 };
  }

  return {};
};

const sharedNoSpaceOnCell = (
  item: IClinicResultCaptureForm,
  column: "resultado" | "unidadNombre" | "referencia"
) => {
  if (item.tipoValorId === parameters.valueType.etiqueta) {
    return { colSpan: 0 };
  }

  if (
    item.tipoValorId === parameters.valueType.observacion &&
    column === "resultado"
  ) {
    return { colSpan: 3 };
  }

  if (
    item.tipoValorId === parameters.valueType.observacion &&
    column !== "resultado"
  ) {
    return { colSpan: 0 };
  }

  return {};
};

const columns: IColumns<IClinicResultCaptureForm> = [
  {
    dataIndex: "nombre",
    title: "Examen",
    className: "no-padding-cell",
    width: "40%",
    onCell: sharedAllSpaceOnCell,
    render: (value, item) => (
      <Text strong={item.tipoValorId === parameters.valueType.etiqueta}>
        {value}
      </Text>
    ),
  },
  {
    dataIndex: "resultado",
    title: "Resultado",
    className: "no-padding-cell",
    width: "20%",
    onCell: (item) => sharedNoSpaceOnCell(item, "resultado"),
    render: (value: string, item) => {
      let isOutOfRange = getIsOutOfRange(item);
      if (
        item.valorInicial?.includes("<") ||
        item.valorInicial?.includes(">")
      ) {
        let operator = item.valorInicial?.includes("<") ? "<" : ">";
        isOutOfRange = !(operator === "<"
          ? parseFloat(value ?? 0) <
            parseFloat(item.valorInicial?.replace("<", ""))
          : parseFloat(value ?? 0) >
            parseFloat(item.valorInicial?.replace(">", "")));
      }

      return <Text type={isOutOfRange ? "danger" : undefined}>{value}</Text>;
    },
  },
  {
    dataIndex: "unidadNombre",
    title: "Unidades",
    className: "no-padding-cell",
    width: "20%",
    onCell: (item) => sharedNoSpaceOnCell(item, "unidadNombre"),
  },
  {
    dataIndex: "referencia",
    title: "Referencia",
    className: "no-padding-cell",
    width: "20%",
    onCell: (item) => sharedNoSpaceOnCell(item, "referencia"),
    render: (_, item) => {
      return item.valorFinal == null && item.valorFinal == null
        ? item.valorInicial
        : `${item.valorInicial ?? ""} - ${item.valorFinal ?? ""}`;
    },
  },
];
