import {
  Spin,
  Form,
  Row,
  Col,
  Pagination,
  Button,
  PageHeader,
  Divider,
  UploadProps,
  Modal,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import React, { FC, Fragment, useEffect, useState } from "react";
import {
  formItemLayout,
  beforeUploadValidation,
  getBase64,
  objectToFormData,
  uploadFakeRequest,
  getComponentBase64,
} from "../../../app/util/utils";
import { useStore } from "../../../app/stores/store";
import { useNavigate, useSearchParams } from "react-router-dom";

import ImageButton from "../../../app/common/button/ImageButton";
import HeaderTitle from "../../../app/common/header/HeaderTitle";
import alerts from "../../../app/util/alerts";
import { observer } from "mobx-react-lite";
import TextAreaInput from "../../../app/common/form/proposal/TextAreaInput";
import DateInput from "../../../app/common/form/proposal/DateInput";
import { IRequestImage } from "../../../app/models/request";
import Upload, { RcFile, UploadFile } from "antd/lib/upload";
import {
  IMantainmentForm,
  MantainValues,
} from "../../../app/models/equipmentMantain";
import moment from "moment";

type EquipmentFormProps = {
  id: number;
  idMantain?: string;
};

const EquipmentForm: FC<EquipmentFormProps> = ({
  id,
  idMantain: idmantain,
}) => {
  const { equipmentMantainStore, optionStore } = useStore();
  const { getSucursalesOptions } = optionStore;
  const {
    getById,
    create,
    update,
    getAlls,
    equipments,
    saveImage,
    search,
    mantain,
    equip,
    idEq,
    deleteImage,
    getequip,
    scopes,
  } = equipmentMantainStore;

  const [type, setType] = useState<"orden" | "ine" | "ineReverso" | "formato">(
    "formato"
  );
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const baseUrl = process.env.REACT_APP_CATALOG_URL + "/images/mantain";

  type imageTypes = {
    order: string;
    id: string;
    idBack: string;
    format: string[];
  };
  const [form] = Form.useForm<IMantainmentForm>();
  const [images, setImages] = useState<imageTypes>({
    order: "",
    id: "",
    idBack: "",
    format: [],
  });
  const [loading, setLoading] = useState(false);
  const [readonly, setReadonly] = useState(
    searchParams.get("mode") === "readonly"
  );

  const [values, setValues] = useState<IMantainmentForm>(new MantainValues());

  useEffect(() => {
    getSucursalesOptions();
  }, [getSucursalesOptions]);

  useEffect(() => {
    const readEquipment = async (id: string) => {
      setLoading(true);

      const equipments = await getById(id);
      var equipo = await getequip(equipments?.ide!);

      search!.idEquipo = equipo?.id!;
      await getAlls(search!);

      setLoading(false);
    };
    if (idmantain) {
      readEquipment(idmantain!);
    }
  }, [getAlls, searchParams]);

  useEffect(() => {
    const readEquipment = async (id: string) => {
      setLoading(true);
      const equipment = await getById(id);
      form.setFieldsValue(equipment!);
      setImages((values) => ({
        ...values,
        format: equipment!.imagenUrl.map((x) => x.imagenUrl),
      }));
      setValues(equipment!);
      setLoading(false);
    };

    if (idmantain) {
      readEquipment(idmantain!);
    }
  }, [form, getById, id]);

  const onFinish = async (newValues: IMantainmentForm) => {
    const equipment = { ...values, ...newValues };
    let success = false;
    let maintainDate = moment(form.getFieldValue("fecha")).utcOffset(0, true);
    equipment.fecha = maintainDate;

    if (!equipment.id) {
      equipment.idEquipo = equip!.id!;
      equipment.ide = id;
      var response = await create(equipment);

      if (response?.id) {
        navigate(`/equipmentMantain/edit/${equip?.id}/${response.id}`);
      }
    } else {
      equipment.clave = mantain?.clave!;
      equipment.no_serie = mantain?.no_serie!;
      success = await update(equipment);
      await sumbitImages();
    }

    if (success) {
      navigate(`/equipmentMantain/${idEq}`);
    }
  };

  const sumbitImages = async () => {
    const formData = objectToFormData(images);
    if (formData.values.length > 0) {
      const ok = await saveImage(formData);

      if (ok) {
        alerts.success("La imagen se ha guardado con éxito");
        return true;
      }
    }
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getComponentBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const onChangeImageFormat: UploadProps["onChange"] = ({ file }) => {
    getBase64(file.originFileObj, (imageStr) => {
      submitImage(type, file.originFileObj!, imageStr!.toString());
    });
  };

  const submitImage = async (
    type: "orden" | "ine" | "ineReverso" | "formato",
    file: RcFile,
    imageUrl: string
  ) => {
    if (mantain) {
      const requestImage: IRequestImage = {
        solicitudId: mantain.id!,
        expedienteId: mantain.id!,
        imagen: file,
        tipo: type,
      };

      setLoading(true);
      const formData = objectToFormData(requestImage);
      const imageName = await saveImage(formData);
      setLoading(false);

      if (imageName) {
        if (type === "orden") {
          setImages({ ...images, order: imageUrl });
        } else if (type === "ine") {
          setImages({ ...images, id: imageUrl });
        } else if (type === "ineReverso") {
          setImages({ ...images, idBack: imageUrl });
        } else if (type === "formato") {
          imageUrl = `/${values?.clave}/${imageName}.png`;
          setImages({
            ...images,
            format: [...images.format.filter((x) => x !== imageUrl), imageUrl],
          });
        }
      }
    }
  };
  const actualMaquilador = () => {
    return equipments.findIndex((x) => x.id === idmantain) + 1;
  };

  const prevnextMaquilador = (index: number) => {
    const maquila = equipments[index];
    navigate(
      `/equipmentMantain/edit/${id}/${maquila.id}?mode=${searchParams.get(
        "mode"
      )}`
    );
  };

  const onRemoveImageFormat = async (file: UploadFile<any>) => {
    if (mantain) {
      setLoading(true);
      var images = values.imagenUrl.find((x) =>
        x.imagenUrl.includes(file.name)
      );
      const ok = await deleteImage(mantain.id, images?.clave!);
      setLoading(false);
      if (ok) {
        setImages((prev) => ({
          ...prev,
          format: prev.format.filter((x) => !x.includes(file.name)),
        }));
      }
      return ok;
    }
    return false;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const getFormatContent = () => {
    return (
      <Fragment>
        <Upload
          customRequest={uploadFakeRequest}
          beforeUpload={(file) => beforeUploadValidation(file)}
          listType="picture-card"
          fileList={images?.format.map((x) => ({
            uid: x,
            name: x.split("/")[x.split("/").length - 1].slice(0, -4),
            url: `${baseUrl}${x}`,
          }))}
          onPreview={handlePreview}
          onChange={onChangeImageFormat}
          onRemove={onRemoveImageFormat}
          disabled={readonly}
        >
          {uploadButton}
        </Upload>
        <Modal
          title={previewTitle}
          open={previewOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </Fragment>
    );
  };

  return (
    <Spin spinning={loading}>
      <Row style={{ marginBottom: 24 }}>
        {idmantain && (
          <Col md={12} sm={24} xs={24} style={{ textAlign: "left" }}>
            <Pagination
              simple
              key="pagination"
              size="small"
              total={equipments.length}
              pageSize={1}
              current={actualMaquilador()}
              onChange={(value) => {
                prevnextMaquilador(value - 1);
              }}
              showSizeChanger={false}
            />
          </Col>
        )}

        {!readonly && scopes.modificar && scopes.acceder && (
          <Col md={idmantain ? 12 : 24} sm={48} style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                navigate(`/equipmentMantain/${idEq}`);
              }}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                form.submit();
              }}
            >
              Guardar
            </Button>
          </Col>
        )}
        {readonly && scopes.modificar && (
          <Col md={12} sm={24} style={{ textAlign: "right" }}>
            {readonly && (
              <ImageButton
                key="edit"
                title="Editar"
                image="editar"
                onClick={() => {
                  setReadonly(false);
                }}
              />
            )}
          </Col>
        )}
      </Row>

      <Form<IMantainmentForm>
        {...formItemLayout}
        form={form}
        name="equipment"
        initialValues={values}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row gutter={[32, 12]}>
          <Col md={12} sm={24}>
            <Row gutter={[4, 12]}>
              <Col span={24}>
                <DateInput
                  formProps={{
                    name: "fecha",
                    label: "Fecha programada",
                  }}
                  readonly={readonly}
                />
              </Col>
              <Col span={24}>
                <TextAreaInput
                  formProps={{
                    name: "descripcion",
                    label: "Observación",
                  }}
                  rows={4}
                  autoSize
                  readonly={readonly}
                />
              </Col>
            </Row>
          </Col>
          {idmantain && scopes.modificar && (
            <Col md={12} sm={24}>
              <label htmlFor="">Imagen</label>
              {getFormatContent()}
            </Col>
          )}
        </Row>
      </Form>
    </Spin>
  );
};
export default observer(EquipmentForm);
