import moment from "moment";
import { v4 as uuid } from "uuid";

import { catalog, status } from "../util/catalogs";
import { generateRandomHex } from "../util/utils";
import { IIndicationList } from "./indication";
import { IParameterList } from "./parameter";
import { IPriceListInfoPromo } from "./priceList";
import { IStudyTag } from "./study";

export interface IRequestBase {
  solicitudId: string;
  expedienteId: string;
}

export interface IRequest extends Omit<IRequestBase, "solicitudId"> {
  solicitudId?: string;
  nombreMedico?: string;
  claveMedico?: string;
  nombreCompania?: string;
  observaciones?: string;
  paciente?: string;
  sucursalId: string;
  sucursal?: string;
  clave?: string;
  estatusId: number;
  clavePatologica?: string;
  citologicalCode?: string;
  parcialidad: boolean;
  registro?: string;
  usuarioRegistro?: string;
  esNuevo: boolean;
  procedencia?: number;
  urgencia?: number;
  folioWeeClinic?: string;
  esWeeClinic: boolean;
  tokenValidado: boolean;
  servicios?: string[];
  saldoPendiente?: boolean;
  serie?: string;
  serieNumero?: string;
  estudios?: IRequestStudyInfo[];
  destino?: string;
  celular?: string;
}

export interface IRequestFilter {
  tipoFecha?: number;
  fechas?: moment.Moment[];
  fechaInicial?: moment.Moment;
  fechaFinal?: moment.Moment;
  clave?: string;
  procedencias?: number[];
  estatus?: number[];
  urgencias?: number[];
  departamentos?: number[];
  ciudad?: string[];
  sucursales?: string[];
  compañias?: string[];
  medicos?: string[];
  expediente?: string;
}

export class RequestFilterForm implements IRequestFilter {
  tipoFecha = 1;
  fechas = [moment().utcOffset(0, true), moment().utcOffset(0, true)];
  fechaInicial = moment().utcOffset(0, true);
  fechaFinal = moment().utcOffset(0, true);

  constructor(init?: IRequestFilter) {
    Object.assign(this, init);
  }
}

export interface IRequestInfo extends IRequestBase {
  clave: string;
  clavePatologica: string;
  sucursal: string;
  paciente: string;
  compañia: string;
  procedencia: string;
  estatusId: number;
  factura: string;
  importe: number;
  descuento: number;
  total: number;
  saldo: number;
  folioWeeClinic?: string;
  esWeeClinic: boolean;
  estudios: IRequestStudyInfo[];
}

export interface IRequestStudyInfo {
  id: number;
  estudioId: number;
  clave: string;
  nombre: string;
  estatusId: number;
  estatus: string;
  color: string;
  fechaTomaMuestra: string;
  fechaValidacion: string;
  fechaSolicitado: string;
  fechaCaptura: string;
  fechaLiberado: string;
  fechaEnviado: string;
  usuarioTomaMuestra: string;
  usuarioValidacion: string;
  usuarioSolicitado: string;
  usuarioCaptura: string;
  usuarioLiberado: string;
  usuarioEnviado: string;
}

export class RequestStudyInfoForm implements IRequestStudyInfo {
  id = 0;
  estudioId = 0;
  clave = "";
  nombre = "";
  estatusId = 0;
  estatus = "";
  color = "";
  fechaTomaMuestra = "";
  fechaValidacion = "";
  fechaSolicitado = "";
  fechaCaptura = "";
  fechaLiberado = "";
  fechaEnviado = "";
  usuarioTomaMuestra = "";
  usuarioValidacion = "";
  usuarioSolicitado = "";
  usuarioCaptura = "";
  usuarioLiberado = "";
  usuarioEnviado = "";

  constructor(init?: IRequestStudyInfo) {
    Object.assign(this, init);
  }
}

export interface IRequestGeneral extends IRequestBase {
  procedencia?: number;
  compañiaId?: string;
  medicoId?: string;
  afiliacion: string;
  urgencia?: number;
  metodoEnvio?: string[];
  correo?: string;
  correos?: string[];
  whatsapp?: string;
  whatsapps?: string[];
  observaciones: string;
  correoMedico?: string;
  telefonoMedico?: string;
  cambioCompañia?: boolean;
  guardadoAutomatico?: boolean;
}

export interface IRequestPayment extends IRequestBase {
  id: string;
  folio: string;
  formaPagoId: number;
  formaPago: string;
  numeroCuenta: string;
  cantidad: number;
  serie: string;
  numero: string;
  estatusId: number;
  usuarioRegistra?: string;
  fechaPago: moment.Moment;
  terminal?: string;
  transaccionId?: string;
  notificacionId?: string;
  lealtad: boolean;
  esRecuperacion: boolean;
  usuarioCancelaId?: string;
  pagoCompañia: boolean;
  facturas: IRequestPaymentInvoice[];

  readonly esTerminal: boolean;
  readonly esPagoNetPay: boolean;
}

export interface IRequestPaymentInvoice {
  id: string;
  solicitudPagoId: string;
  facturaId: string;
  facturapiId: string;
  serieFactura: string;
  estatus: string;
}

export class RequestPaymentClass implements IRequestPayment {
  id: string = uuid();
  folio: string = generateRandomHex(9) + moment().format("YYMMDD");
  formaPagoId: number = 0;
  formaPago: string = "";
  numeroCuenta: string = "";
  cantidad: number = 0;
  serie: string = "";
  numero: string = "";
  estatusId: number = status.requestPayment.pagado;
  facturaId?: string | undefined;
  serieFactura: string = "";
  facturapiId: string = "";
  usuarioRegistra?: string | undefined;
  fechaPago: moment.Moment = moment();
  terminal?: string | undefined;
  transaccionId?: string | undefined;
  notificacionId?: string | undefined;
  lealtad: boolean = false;
  solicitudId: string = "";
  expedienteId: string = "";
  esRecuperacion: boolean = false;
  pagoCompañia: boolean = false;
  facturas: IRequestPaymentInvoice[] = [];

  get esTerminal() {
    return (
      this.formaPagoId === catalog.paymentForms.tarjetaCredito ||
      this.formaPagoId === catalog.paymentForms.tarjetaDebito
    );
  }

  get esPagoNetPay() {
    return this.terminal?.toLowerCase()?.includes("netpay") ?? false;
  }

  constructor(init?: IRequestPayment) {
    Object.assign(this, init);
  }
}

export interface IRequestCheckIn extends IRequestBase {
  datoFiscalId: string;
  serie: string;
  usoCFDI: string;
  formaPago: string;
  tasaIva: number;
  desglosado: boolean;
  simple: boolean;
  porConcepto: boolean;
  envioCorreo: boolean;
  envioWhatsapp: boolean;
  checkPaciente: boolean;
  pagos: IRequestPayment[];
  detalle: IRequestCheckInDetail[];
}

export interface IRequestCheckInDetail {
  clave: string;
  descripcion: string;
  precio: number;
  cantidad: number;
  descuento: number;
}

export interface IRequestInvoiceDownload extends IRequestBase {
  pagos: string[];
}

export interface IRequestImage extends IRequestBase {
  imagen?: File | Blob;
  imagenUrl?: string;
  tipo: "orden" | "ine" | "ineReverso" | "formato";
}

export interface IRequestPartiality extends IRequestBase {
  aplicar: boolean;
}

export interface IRequestSend extends IRequestBase {
  correo?: string;
  telefono?: string;
}

export interface IRequestStudyUpdate extends IRequestBase {
  estudios: IRequestStudy[];
  paquetes?: IRequestPack[];
  etiquetas?: IRequestTag[];
  total?: IRequestTotal;
}

export interface IRequestStudiesDatesUpdate {
  id: number | undefined;
  fechaEntrega: string;
}

export class RequestStudyUpdate implements IRequestStudyUpdate {
  estudios: IRequestStudy[] = [];
  paquetes: IRequestPack[] = [];
  total: IRequestTotal = new RequestTotal();
  solicitudId: string = "";
  expedienteId: string = "";

  constructor(init?: IRequestTotal) {
    Object.assign(this, init);
  }
}

export interface IRequestStudy {
  type: "study" | "pack";
  id?: number;
  identificador?: string;
  estudioId: number;
  clave: string;
  nombre: string;
  paqueteId?: number;
  paquete?: string;
  listaPrecioId: string;
  listaPrecio: string;
  promocionId?: number;
  promocion?: string;
  promocionIdOriginal?: number;
  promocionOriginal?: string;
  estatusId: number;
  estatus?: string;
  departamentoId: number;
  areaId: number;
  dias: number;
  horas: number;
  fechaEntrega: moment.Moment | string;
  precio: number;
  descuento?: number;
  descuentoPorcentaje?: number;
  descuentoOriginal?: number;
  ordenEstudio: number;
  metodo?: string;
  copago?: number;
  precioFinal: number;
  nuevo: boolean;
  asignado: boolean;
  promociones: IPriceListInfoPromo[];
  parametros: IParameterList[];
  indicaciones: IIndicationList[];
  etiquetas: IStudyTag[];
  fechaActualizacion?: string;
  usuarioActualizacion?: string;
  claveUsuarioActualizacion?: string;
  solicitudEstudioId?: number;
  nombreEstatus?: string;
  fechaTomaMuestra?: moment.Moment;
  fechaValidacion?: string;
  fechaSolicitado?: string;
  fechaCaptura?: string;
  fechaLiberado?: string;
  fechaEnviado?: string;
  usuarioTomaMuestra?: string;
  claveUsuarioTomaMuestra?: string;
  usuarioValidacion?: string;
  claveUsuarioValidacion?: string;
  usuarioSolicitado?: string;
  claveUsuarioSolicitado?: string;
  usuarioCaptura?: string;
  claveUsuarioCaptura?: string;
  usuarioLiberado?: string;
  claveUsuarioLiberado?: string;
  usuarioEnviado?: string;
  claveUsuarioEnviado?: string;
  tipo?: string;
  destinoTipo: number;
  destinoId: string;
  destino: string;
  descuentoManual: boolean;
  descuentoModificado?: boolean;
  sucursalCaptura?: string;
  capturado?: boolean;
  pathologicalPdf?: string;
  clavePatologica?: string;
  // isActiveCheckbox?: boolean;
}

export class RequestStudyValues implements IRequestStudy {
  type: "study" | "pack" = "study";
  id = 0;
  estudioId = 0;
  ordenEstudio = 0;
  clave = "";
  nombre = "";
  taponId = 0;
  taponColor = "";
  taponClave = "";
  taponNombre = "";
  paqueteId = 0;
  paquete = "";
  listaPrecioId = "";
  listaPrecio = "";
  promocionId = 0;
  promocion = "";
  estatusId = 0;
  estatus = "";
  departamentoId = 0;
  areaId = 0;
  dias = 0;
  horas = 0;
  fechaEntrega = moment().utcOffset(0, true);
  precio = 0;
  descuento = 0;
  descuentoPorcentaje = 0;
  copago = 0;
  precioFinal = 0;
  nuevo = false;
  asignado = true;
  parametros: IParameterList[] = [];
  indicaciones: IIndicationList[] = [];
  etiquetas: IStudyTag[] = [];
  promociones = [];
  fechaActualizacion = "";
  usuarioActualizacion = "";
  destinoTipo = 1;
  destinoId = "";
  destino = "";
  descuentoManual: boolean = false;
  // isActiveCheckbox = false;

  constructor(init?: IRequestStudy) {
    Object.assign(this, init);
  }
}

export interface IRequestPack {
  type: "study" | "pack";
  id?: number;
  identificador?: string;
  paqueteId: number;
  clave: string;
  nombre: string;
  listaPrecioId: string;
  listaPrecio: string;
  promocionId?: number;
  promocion?: string;
  promocionIdOriginal?: number;
  promocionOriginal?: string;
  estatusId?: number;
  estatus?: string;
  cancelado: boolean;
  departamentoId: number;
  areaId: number;
  dias: number;
  horas: number;
  precioEstudios: number;
  paqueteDescuento: number;
  paqueteDescuentoPorcentaje: number;
  precio: number;
  descuento?: number;
  descuentoOriginal?: number;
  descuentoPorcentaje?: number;
  precioFinal: number;
  nuevo: boolean;
  asignado: boolean;
  promociones: IPriceListInfoPromo[];
  estudios: IRequestStudy[];
  descuentoManual: boolean;
  descuentoModificado?: boolean;
  // isActiveCheckbox?: boolean;
}

export interface RequestStudyRequestTable {
  id?: number;
  identificador?: string;
  clave: string;
  nombre: string;
  estatusId?: number;
  estatus?: string;
  dias: number;
  fechaSolicitado?: string;
  fechaEntrega?: moment.Moment;
  estudios: IRequestStudy[];
  isPack: boolean;
}

export interface IRequestTag {
  id: string | number;
  clave: string;
  destinoId: string;
  destino: string;
  destinoTipo: number;
  etiquetaId: number;
  claveEtiqueta: string;
  claveInicial: string;
  nombreEtiqueta: string;
  cantidad: number;
  color: string;
  observaciones?: string;
  estudios: IRequestTagStudy[];
  manual?: boolean;
  temp?: string;
}

export interface IRequestTagStudy {
  id: number | string;
  solicitudEstudioId?: number;
  identificador?: string;
  estudioId: number;
  nombreEstudio: string;
  orden: number;
  cantidad: number;
  claveEstudio: string;
  asignado: boolean;
  etiquetaId?: number;
}

export interface IRequestTotal extends IRequestBase {
  totalEstudios: number;
  descuento: number;
  cargo: number;
  copago: number;
  total: number;
  saldo: number;
  pagos: number;
}

export class RequestTotal implements IRequestTotal {
  totalEstudios: number = 0;
  descuento: number = 0;
  cargo: number = 0;
  copago: number = 0;
  total: number = 0;
  saldo: number = 0;
  pagos: number = 0;
  solicitudId: string = "";
  expedienteId: string = "";

  constructor(init?: IRequestTotal) {
    Object.assign(this, init);
  }
}

export interface IRequestToken extends IRequestBase {
  token?: string;
  reenviar: boolean;
}
