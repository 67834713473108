import {
  Checkbox,
  Col,
  Form,
  InputNumber,
  Popover,
  Row,
  Table,
  Typography,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { VList } from "virtual-table-ant-design";
import {
  IColumns,
  ISearch,
  getDefaultColumnProps,
} from "../../../../app/common/table/utils";
import TextInput from "../../../../app/common/form/proposal/TextInput";
import { formItemLayout, moneyFormatter } from "../../../../app/util/utils";
import {
  IPriceListPack,
  IPriceListStudy,
} from "../../../../app/models/priceList";
import { observer } from "mobx-react-lite";
import { InfoOutlined } from "@ant-design/icons";
import IconButton from "../../../../app/common/button/IconButton";
import "../css/index.css";

const { Text } = Typography;

type Props = {
  packs: IPriceListPack[];
  setPacks: (packs: IPriceListPack[]) => void;
  readonly: boolean;
};

const PriceListFormPacks = ({ packs, setPacks, readonly }: Props) => {
  const [filterForm] = Form.useForm();

  const code = Form.useWatch("clave", filterForm);

  const [filteredPacks, setFilteredPacks] = useState<IPriceListPack[]>([]);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  useEffect(() => {
    const filtered = packs.filter(
      (x) =>
        x.clave.toLowerCase().indexOf(code?.toLowerCase()) > -1 ||
        x.nombre.toLowerCase().indexOf(code?.toLowerCase()) > -1 ||
        !code
    );
    setFilteredPacks(filtered);
  }, [code, packs]);

  const changeDiscountPercentage = (item: IPriceListPack, discount: number) => {
    const index = packs.findIndex((x) => x.paqueteId === item.paqueteId);

    if (index === -1 || discount == null) return;

    const pricePacks = [...packs];
    const pack = pricePacks[index];
    pack.descuentoPorcentaje = Number(discount.toFixed(2));
    pack.descuentoCantidad = Number(
      ((pack.precio * discount) / 100).toFixed(2)
    );
    pack.precioFinal = pack.precio - pack.descuentoCantidad;
    pricePacks[index] = pack;

    setPacks(pricePacks);
  };

  const changeDiscountQty = (item: IPriceListPack, discount: number) => {
    const index = packs.findIndex((x) => x.paqueteId === item.paqueteId);

    if (index === -1 || discount == null) return;

    const pricePacks = [...packs];
    const pack = pricePacks[index];
    pack.descuentoCantidad = Number(discount.toFixed(2));
    pack.descuentoPorcentaje = Number(
      ((discount * 100) / item.precio).toFixed(2)
    );
    pack.precioFinal = pack.precio - pack.descuentoCantidad;
    pricePacks[index] = pack;

    setPacks(pricePacks);
  };

  const onChangeActive = (paqueteId: number, active: boolean) => {
    const paquetes = [...packs];
    const index = paquetes.findIndex((x) => x.paqueteId === paqueteId);
    if (index === -1) return;

    paquetes[index].activo = active;
    setPacks(paquetes);
  };

  const StudiesInfo = ({ studies }: { studies: IPriceListStudy[] }) => {
    if (studies.length === 0) {
      return (
        <div style={{ padding: "12px 16px" }}>
          <Text>El paquete no contiene estudios</Text>
        </div>
      );
    }

    return (
      <Table<IPriceListStudy>
        style={{ width: 520 }}
        rowKey={(record) => record.estudioId}
        columns={[
          { dataIndex: "clave", width: 100, ellipsis: true },
          { dataIndex: "nombre", width: 300, ellipsis: true },
          {
            dataIndex: "precio",
            width: 100,
            render: (price) => (
              <Text type={price === 0 ? "danger" : undefined}>
                {moneyFormatter.format(price)}
              </Text>
            ),
          },
        ]}
        pagination={false}
        dataSource={[...studies]}
        showHeader={false}
        scroll={{ x: 500, y: 240 }}
      />
    );
  };

  const columns: IColumns<IPriceListPack> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: 80,
      }),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre", {
        searchState,
        setSearchState,
        width: 200,
      }),
    },
    {
      key: "info",
      dataIndex: "estudios",
      align: "center",
      width: 50,
      render: (studies: IPriceListStudy[], item) => {
        if (!studies) return "";
        return (
          <Popover
            overlayClassName="popover-prices"
            content={<StudiesInfo studies={studies} />}
            title="Estudios"
            trigger="click"
          >
            <IconButton
              icon={<InfoOutlined />}
              danger={
                studies.length === 0 || studies.some((x) => x.precio <= 0)
              }
            />
          </Popover>
        );
      },
    },
    {
      key: "descuentoPorcentaje",
      dataIndex: "descuentoPorcentaje",
      title: "Desc %",
      align: "center",
      width: 100,
      render: (value, item) => (
        <InputNumber
          type={"number"}
          min={0}
          max={100}
          value={value}
          onChange={(value) => changeDiscountPercentage(item, value)}
          disabled={
            readonly ||
            (item.estudios &&
              (item.estudios.length === 0 ||
                item.estudios.some((x) => x.precio <= 0)))
          }
        ></InputNumber>
      ),
    },
    {
      key: "descuentoCantidad",
      dataIndex: "descuentoCantidad",
      title: "Desc $",
      align: "center",
      width: 100,
      render: (value, item) => (
        <InputNumber
          type={"number"}
          min={0}
          max={item.precio}
          value={value}
          onChange={(value) => changeDiscountQty(item, value)}
          disabled={
            readonly ||
            (item.estudios &&
              (item.estudios.length === 0 ||
                item.estudios.some((x) => x.precio <= 0)))
          }
        ></InputNumber>
      ),
    },
    {
      ...getDefaultColumnProps("precio", "Precio", {
        searchState,
        setSearchState,
        width: 100,
      }),
      render: (value, item) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("precioFinal", "Precio final", {
        searchState,
        setSearchState,
        width: 100,
      }),
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("activo", "Activo", {
        searchState,
        setSearchState,
        width: 30,
      }),
      align: "center",
      render: (value: boolean, record: IPriceListPack) => {
        return (
          <Checkbox
            checked={value}
            onChange={(e) => {
              onChangeActive(record.paqueteId, e.target.checked);
            }}
          ></Checkbox>
        );
      },
    },
  ];

  const virtual = useMemo(() => {
    return VList({
      height: 500,
    });
  }, []);

  return (
    <div>
      <Form
        style={{ marginBottom: 12 }}
        {...formItemLayout}
        form={filterForm}
        name="filter"
      >
        <Row>
          <Col span={8} offset={16}>
            <TextInput formProps={{ name: "clave", label: "Buscar" }} />
          </Col>
        </Row>
      </Form>
      <Table<IPriceListPack>
        rowKey={(record) => record.paqueteId}
        columns={columns}
        pagination={false}
        dataSource={[...filteredPacks]}
        components={virtual}
        scroll={{
          x: "100%",
          y: 500,
        }}
      />
    </div>
  );
};

export default observer(PriceListFormPacks);
