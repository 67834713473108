import { Button, Input, PageHeader, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import HeaderTitle from "../../app/common/header/HeaderTitle";
import { useStore } from "../../app/stores/store";

import { observer } from "mobx-react-lite";
import DownloadFileIcon from "../../app/common/icons/DownloadFileIcon";

const { Text } = Typography;

const { Search } = Input;

type RouteHeaderProps = {
  handlePrint: () => void;
  handleDownload: () => Promise<void>;
};

const RouteTrackingHeader: FC<RouteHeaderProps> = ({
  handlePrint,
  handleDownload,
}) => {
  const { routeStore, profileStore, optionStore, routeTrackingStore } =
    useStore();
  const { scopes, getAll } = routeStore;
  const { profile } = profileStore;
  const { BranchOptions, getBranchOptions } = optionStore;
  const { receiveTags, pendingTags, historyTags, currentTrackingTab } =
    routeTrackingStore;

  const [currentBranch, setCurrentBranch] = useState<string | undefined>("");

  useEffect(() => {
    getBranchOptions();
  }, []);

  useEffect(() => {
    if (profile && !!BranchOptions.length) {
      const branch = BranchOptions.find((x) => x.value === profile?.sucursal);
      setCurrentBranch(branch?.label as string);
    }
  }, [BranchOptions, profile]);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const search = async (search: string | undefined) => {
    search = search === "" ? undefined : search;

    await getAll(search ?? "all");

    if (search) {
      searchParams.set("search", search);
    } else {
      searchParams.delete("search");
    }

    setSearchParams(searchParams);
  };

  return (
    <PageHeader
      ghost={false}
      title={<HeaderTitle title="Seguimiento de Rutas" image="ruta" />}
      className="header-container"
      extra={[
        scopes.descargar && (
          <Button
            type="text"
            icon={<DownloadFileIcon />}
            onClick={handleDownload}
            disabled={
              currentTrackingTab === "1"
                ? !pendingTags.length
                : currentTrackingTab === "2"
                ? !receiveTags.length
                : !historyTags.length
            }
          ></Button>
          // <DownloadIcon key="download" onClick={handleDownload} />
        ),
        <Text>
          Sucursal activa: <strong>{currentBranch}</strong>
        </Text>,
      ]}
    ></PageHeader>
  );
};

export default observer(RouteTrackingHeader);
