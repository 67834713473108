import { Button, Table, Form, Row, Col, Modal } from "antd";
import React, { FC, Fragment, useEffect, useState } from "react";
import {
  defaultPaginationProperties,
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../app/common/table/utils";
import { formItemLayout } from "../../app/util/utils";
import { IUserList, IChangePasswordForm } from "../../app/models/user";
import { EditOutlined, LockOutlined } from "@ant-design/icons";
import IconButton from "../../app/common/button/IconButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import PasswordInput from "../../app/common/form/PasswordInput";

type UserTableProps = {
  printing: boolean;
};

const UserTable: FC<UserTableProps> = ({ printing }) => {
  const { userStore } = useStore();
  const { users, getAll, changePassword, scopes } = userStore;

  let navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [formPassword] = Form.useForm<IChangePasswordForm>();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    formPassword.submit();
  };

  const handleCancel = () => {
    formPassword.resetFields();
    setIsModalVisible(false);
  };

  const onFinishPass = async (newValues: IChangePasswordForm) => {
    let passForm = { ...newValues, id: userId };
    const success = await changePassword(passForm);
    if (success) {
      formPassword.resetFields();
      setIsModalVisible(false);
    }
  };

  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  useEffect(() => {
    const readUsers = async () => {
      setLoading(true);
      await getAll(searchParams.get("search") ?? "all");
      setLoading(false);
    };

    readUsers();
  }, [getAll, searchParams]);

  const columns: IColumns<IUserList> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: "20%",
      }),
      render: (value, user) => (
        <Button
          type="link"
          onClick={() => {
            navigate(
              `/users/${user.id}?mode=ReadOnly&search=${
                searchParams.get("search") ?? "all"
              }`
            );
          }}
        >
          {value}
        </Button>
      ),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre", {
        searchState,
        setSearchState,
        width: "25%",
      }),
    },
    {
      ...getDefaultColumnProps("tipoUsuario", "Tipo de usuario", {
        searchState,
        setSearchState,
        width: "25%",
      }),
    },
    {
      key: "activo",
      dataIndex: "activo",
      title: "Activo",
      align: "center",
      width: "10%",
      render: (value) => (value ? "Sí" : "No"),
    },
    {
      key: "contraseña",
      dataIndex: "contraseña",
      title: "Contraseña",
      align: "center",
      width: "10%",
      render: (value, user) =>
        scopes.modificar && (
          <IconButton
            title="Cambiar contraseña"
            onClick={() => {
              setUserId(user.id);
              showModal();
            }}
            icon={<LockOutlined />}
          />
        ),
    },
    {
      key: "editar",
      dataIndex: "id",
      title: "Editar",
      align: "center",
      width: "10%",
      render: (value, user) =>
        scopes.modificar && (
          <IconButton
            title="Editar usuario"
            icon={<EditOutlined />}
            onClick={() => {
              navigate(
                `/users/${user.id}?search=${
                  searchParams.get("search") ?? "all"
                }`
              );
            }}
          />
        ),
    },
  ];

  return (
    <Fragment>
      <Table<IUserList>
        loading={loading || printing}
        size="small"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={[...users]}
        pagination={defaultPaginationProperties}
        sticky
        scroll={{ x: "max-content" }}
      />
      <Modal
        title="Ingreso de contraseña"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form<IChangePasswordForm>
          {...formItemLayout}
          form={formPassword}
          initialValues={{}}
          name="changepassword"
          onFinish={onFinishPass}
          scrollToFirstError
        >
          <Row>
            <Col md={24} sm={24} xs={24}>
              <PasswordInput
                formProps={{
                  name: "contraseña",
                  label: "Nueva Contraseña",
                }}
                max={14}
                min={8}
                required
              />
            </Col>
            <Col md={24} sm={24} xs={24}>
              <PasswordInput
                formProps={{
                  name: "confirmaContraseña",
                  label: "Confirmar Contraseña",
                }}
                max={14}
                min={8}
                required
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default observer(UserTable);
