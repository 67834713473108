import { IReagentList } from "./reagent";
import { IStudyList } from "./study";

export interface IParameterList {
  id: string;
  clave: string;
  nombre: string;
  nombreCorto: string;
  area: string;
  departamento: string;
  activo: boolean;
  requerido: boolean;
  deltaCheck: boolean;
  mostrarFormato: boolean;
  unidades: number;
  unidadNombre: string;
  tipoValor: string;
  valorInicial: string;
  valorFinal: string;
  criticoMinimo?: number;
  criticoMaximo?: number;
  solicitudEstudioId: number;
  hasHistory?: boolean;
  resultadoId: string;
  resultado: string;
  ultimoResultado?: string;
  ultimaSolicitud?: string;
  ultimoExpedienteId?: string;
  ultimaSolicitudId?: string;
  formula?: string;
  observacionesId?: string;
  editable: boolean;
  index?: number;
  tipoValores?: ItipoValorForm[];
  tipo: string;
}

export interface IParameterValueListDto {
  id: string;
  clave: string;
  nombre: string;
  nombreCorto: string;
  area: string;
  departamento: string;
  activo: boolean;
  requerido: boolean;
  deltaCheck: boolean;
  mostrarFormato: boolean;
  unidades: number;
  unidadNombre: string;
  tipoValor: string;
  valorInicial: number;
  valorFinal: number;
  solicitudEstudioId: number;
  tipoValores: ItipoValorForm[];
}

export interface IParameterForm {
  id: string;
  clave: string;
  nombre: string;
  nombreCorto: string;
  unidades?: number;
  tipoValor: any;
  formula?: string;
  valorInicial: string;
  departamentoId?: number;
  areaId?: number;
  unidadSi?: number;
  fcsi: string;
  activo: boolean;
  requerido: boolean;
  deltaCheck: boolean;
  mostrarFormato: boolean;
  valorCriticos: boolean;
  estudios: IStudyList[];
  FormatoImpresionId?: number;
  funciones?: string;
  parametros?: string;
  reactivos: IReagentList[];
  ocultarSiNulo: boolean;
  valoresReferencia?: ItipoValorForm[];
}

export interface ItipoValorForm {
  id?: string;
  parametroId?: string;
  nombre?: string;
  valorInicial?: string;
  valorFinal?: string;
  valorInicialNumerico?: string;
  valorFinalNumerico?: string;
  rangoEdadInicial?: number;
  rangoEdadFinal?: number;
  hombreValorInicial?: string;
  hombreValorFinal?: string;
  mujerValorInicial?: string;
  mujerValorFinal?: string;
  criticoMinimo?: string;
  criticoMaximo?: string;
  hombreCriticoMinimo?: string;
  hombreCriticoMaximo?: string;
  mujerCriticoMinimo?: string;
  mujerCriticoMaximo?: string;
  medidaTiempoId?: number;
  opcion?: string | "";
  descripcionTexto?: string | "";
  descripcionParrafo?: string | "";
  primeraColumna?: string | "";
  segundaColumna?: string | "";
  terceraColumna?: string | "";
  cuartaColumna?: string | "";
  quintaColumna?: string | "";
  orden: number;
}

export interface IReagentForm {
  id: string;
  reactivo: string[];
  seleccion: boolean;
}

export interface Itipovalor {
  values: ItipoValorForm[];
  idParameter: String;
}

export class ParameterFormValues implements IParameterForm {
  id = "";
  clave = "";
  nombre = "";
  nombreCorto = "";
  tipoValor = "0";
  valorInicial = "";
  fcsi = "";
  activo = true;
  requerido = false;
  deltaCheck = false;
  mostrarFormato = false;
  valorCriticos = false;
  estudios: IStudyList[] = [];
  reactivos: IReagentList[] = [];
  FormatoImpresionId = undefined;
  ocultarSiNulo: boolean = false;

  constructor(init?: IParameterForm) {
    Object.assign(this, init);
  }
}

export class tipoValorFormValues implements ItipoValorForm {
  id = "";
  parametroId = "";
  nombre = "";
  valorInicial = "";
  valorFinal = "";
  valorInicialNumerico = "";
  valorFinalNumerico = "";
  rangoEdadInicial = 0;
  rangoEdadFinal = 0;
  hombreValorInicial = "";
  hombreValorFinal = "";
  mujerValorInicial = "";
  mujerValorFinal = "";
  hombreCriticoMinimo = "";
  hombreCriticoMaximo = "";
  mujerCriticoMinimo = "";
  mujerCriticoMaximo = "";
  medidaTiempo = 0;
  opcion = "";
  descripcionTexto = "";
  descripcionParrafo = "";
  primeraColumna = "";
  segundaColumna = "";
  terceraColumna = "";
  cuartaColumna = "";
  quintaColumna = "";
  orden = 1;

  constructor(init?: ItipoValorForm) {
    Object.assign(this, init);
  }
}

export interface IObservations {
  id: number;
  observacion: string;
}
