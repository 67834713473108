import { makeAutoObservable, reaction, toJS } from "mobx";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import NetPay from "../api/netPay";
import PriceList from "../api/priceList";
import Request from "../api/request";
import { IGeneralForm } from "../models/general";
import { IPriceListInfoFilter } from "../models/priceList";
import {
  IRequest,
  IRequestCheckIn,
  IRequestGeneral,
  IRequestInfo,
  IRequestInvoiceDownload,
  IRequestPack,
  IRequestPartiality,
  IRequestPayment,
  IRequestStudiesDatesUpdate,
  IRequestStudy,
  IRequestStudyUpdate,
  IRequestTag,
  IRequestTagStudy,
  IRequestToken,
  IRequestTotal,
  RequestPaymentClass,
  RequestStudyUpdate,
  RequestTotal,
} from "../models/request";
import { IScopes, Scopes } from "../models/shared";
import { ITag } from "../models/tag";
import alerts from "../util/alerts";
import { catalog, status, statusName } from "../util/catalogs";
import history from "../util/history";
import messages from "../util/messages";
import { getDistinct, getErrors, toFixedNumber } from "../util/utils";
import { store } from "./store";

export default class RequestStore {
  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.studies.slice(),
      () => {
        this.calculateTotals();
      }
    );

    reaction(
      () => this.packs.slice(),
      () => {
        this.calculateTotals();
      }
    );

    reaction(
      () => this.payments.slice(),
      () => {
        this.calculateTotals();
      }
    );

    reaction(
      () => this.request?.urgencia,
      () => {
        this.calculateTotals();
      }
    );

    reaction(
      () => this.request?.procedencia,
      () => {
        this.calculateTotals();
      }
    );
  }

  scopes: IScopes = new Scopes();
  studyFilter: IPriceListInfoFilter = {};
  requests: IRequestInfo[] = [];
  request?: IRequest;
  totals: IRequestTotal = new RequestTotal();
  totalsOriginal: IRequestTotal = new RequestTotal();
  studies: IRequestStudy[] = [];
  packs: IRequestPack[] = [];
  payments: IRequestPayment[] = [];
  loadingRequests: boolean = false;
  loadingNetPay: boolean = false;
  loadingTabContentCount: number = 0;
  lastViewedFrom?: { from: "requests" | "results"; code: string };
  tags: IRequestTag[] = [];
  availableTagStudies: IRequestTagStudy[] = [];
  lastBalance: number = 0;
  isUpdating: boolean = false;
  disabledClave: boolean = false;
  focusClave: boolean = false;
  focusType: string = "";

  tabKey: string = "general";

  setTabKey = (tabName: string) => {
    this.tabKey = tabName;
  };

  setDisabledClave = (disabled: boolean) => {
    this.disabledClave = disabled;
  };
  setFocusType = (type: string) => {
    this.focusType = type;
  };

  setFocusClave = (disabled: boolean) => {
    this.focusClave = disabled;
  };

  setTags = (tags: IRequestTag[]) => {
    this.tags = tags;
  };

  get loadingTabContent() {
    return this.loadingTabContentCount > 0;
  }

  get studyUpdate() {
    if (this.request) {
      const data: IRequestStudyUpdate = {
        expedienteId: this.request.expedienteId,
        solicitudId: this.request.solicitudId!,
        paquetes: this.packs,
        estudios: this.studies,
        total: {
          ...this.totals,
          expedienteId: this.request.expedienteId,
          solicitudId: this.request.solicitudId!,
        },
      };
      return data;
    }
    return new RequestStudyUpdate();
  }

  get allStudies() {
    const packStudies = this.packs.flatMap((x) => x.estudios);
    const studies = this.studies;

    return [...studies, ...packStudies].sort(
      (a, b) => a.ordenEstudio - b.ordenEstudio
    );
  }

  get allActiveStudies() {
    return this.allStudies.filter(
      (x) => x.estatusId !== status.requestStudy.cancelado && x.asignado
    );
  }

  get activePayments() {
    return this.payments.filter(
      (x) => x.estatusId !== status.requestPayment.cancelado
    );
  }

  get discountModified() {
    return (
      this.studies.some((x) => x.descuentoModificado) ||
      this.packs.some((x) => x.descuentoModificado)
    );
  }

  get distinctTags() {
    return getDistinct(
      this.tags.map((x) => ({
        etiquetaId: x.etiquetaId,
        claveEtiqueta: x.claveEtiqueta,
        nombreEtiqueta: x.nombreEtiqueta,
        color: x.color,
        claveInicial: x.claveInicial,
      }))
    );
  }

  get hasStudies() {
    return this.allActiveStudies.length > 0;
  }

  get readonly() {
    return !this.scopes.modificar;
  }

  clearSerie = () => {
    if (this.request) {
      this.request.serie = undefined;
      this.request.serieNumero = undefined;
    }
  };

  clearDetailData = () => {
    this.request = undefined;
    this.tags = [];
    this.studies = [];
    this.packs = [];
    this.payments = [];
    this.totals = new RequestTotal();
  };
  loadingClave: boolean = false;
  updatePathologicalCodes = async (pathCodes: any) => {
    try {
      this.loadingClave = true;
      await Request.updatePathologicalCodes(pathCodes);
      this.loadingClave = false;
      alerts.success(messages.updated);
      return true;
    } catch (error) {
      this.loadingClave = false;
      alerts.warning(getErrors(error));
      return false;
    }
  };

  clearTags = () => {
    this.tags = [];
  };

  clearStudies = () => {
    this.studies = [];
    this.packs = [];
  };

  setOriginalTotal = (totals: IRequestTotal) => {
    this.totalsOriginal = totals;
  };

  isPack(obj: IRequestStudy | IRequestPack): obj is IRequestPack {
    return obj.type === "pack";
  }

  isStudy(obj: IRequestStudy | IRequestPack): obj is IRequestStudy {
    return obj.type === "study";
  }

  setLastViewedCode = (
    data:
      | {
          from: "requests" | "results";
          code: string;
        }
      | undefined
  ) => {
    this.lastViewedFrom = data;
  };

  setStudyFilter = (
    branchId?: string,
    doctorId?: string,
    companyId?: string
  ) => {
    this.studyFilter = {
      sucursalId: branchId,
      medicoId: doctorId,
      compañiaId: companyId,
    };
  };

  setStudy = (study: IRequestStudy) => {
    const index = this.studies.findIndex(
      (x) => (x.id ?? x.identificador!) === (study.id ?? study.identificador!)
    );

    if (index > -1) {
      this.studies[index] = study;
    }
    this.packs = this.packs.map((p) => {
      const index = p.estudios.findIndex(
        (x) => (x.id ?? x.identificador!) === (study.id ?? study.identificador!)
      );
      if (index > -1) {
        p.estudios[index] = study;
      }
      return p;
    });
  };

  setPack = (pack: IRequestPack) => {
    const index = this.packs.findIndex(
      (x) => (x.id ?? x.identificador) === (pack.id ?? pack.identificador)
    );

    if (index > -1) {
      this.packs[index] = pack;
    }
  };

  setPartiality = (apply: boolean) => {
    if (this.request) {
      this.request.parcialidad = apply;
    }
  };

  getById = async (
    recordId: string,
    requestId: string,
    from: "requests" | "results"
  ) => {
    try {
      const request = await Request.getById(recordId, requestId);
      this.lastViewedFrom = { from: from, code: request.clave! };
      this.request = request;
    } catch (error) {
      alerts.warning(getErrors(error));
      history.push("/notFound");
    }
  };

  getRequests = async (filter: IGeneralForm) => {
    try {
      this.loadingRequests = true;
      const requests = await Request.getRequests(filter);
      this.requests = requests;
      return requests;
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingRequests = false;
    }
  };
  getRequestsDashboard = async (filter: IGeneralForm) => {
    try {
      this.loadingRequests = true;
      const requests = await Request.getRequestsDashboard(filter);
      this.requests = requests;
      return requests;
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingRequests = false;
    }
  };

  getLocalRequests = async (filter: IGeneralForm) => {
    try {
      const requests = await Request.getRequests(filter);
      return requests;
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  getGeneral = async (recordId: string, requestId: string) => {
    try {
      this.loadingTabContentCount++;
      const request = await Request.getGeneral(recordId, requestId);
      request.metodoEnvio = [];
      request.correos = [];
      request.whatsapps = [];

      if (request.correo) {
        request.metodoEnvio.push("correo");
        request.correos = request.correo.split(",");
      }
      if (request.whatsapp) {
        request.metodoEnvio.push("whatsapp");
        request.whatsapps = request.whatsapp.split(",");
      }

      if (request.metodoEnvio.length === 2) request.metodoEnvio.push("ambos");
      return request;
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  getStudies = async (
    recordId: string,
    requestId: string,
    filter?: IGeneralForm
  ) => {
    try {
      this.loadingTabContentCount++;
      const data = await Request.getStudies(recordId, requestId, filter);
      if (data.paquetes && data.paquetes.length > 0) {
        this.packs = data.paquetes;
      }
      this.studies = data.estudios.map((x) => ({
        ...x,
        identificador: uuidv4(),
      }));
      this.totals = data.total ?? new RequestTotal();
      return data;
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    } finally {
      this.loadingTabContentCount--;
    }
  };

  getPayments = async (recordId: string, requestId: string) => {
    try {
      const payments = await Request.getPayments(recordId, requestId);
      this.payments = payments;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  getTags = async (recordId: string, requestId: string) => {
    try {
      const tags = await Request.getTags(recordId, requestId);
      // this.updateTagsStudy();
      this.tags = tags;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  getImages = async (recordId: string, requestId: string) => {
    try {
      const data = await Request.getImages(recordId, requestId);
      return data;
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  getNextPaymentCode = async (serie: string) => {
    try {
      const data = await Request.getNextPaymentCode(serie);
      return data.toString();
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  getPriceStudy = async (studyId: number, filter: IPriceListInfoFilter) => {
    try {
      this.loadingTabContentCount++;

      filter.estudioId = studyId;

      const price = await PriceList.getPriceStudy(filter);
      const study: IRequestStudy = {
        ...price,
        type: "study",
        estatusId: status.requestStudy.tomaDeMuestra,
        estatus: statusName.requestStudy.tomaDeMuestra,
        nuevo: true,
        asignado: true,
        descuentoManual: false,
      };

      const { isRepeated, repeatedMessage } =
        this.checkDuplicatesInStudies(study);

      if (isRepeated) {
        alerts.confirm(
          "Coincidencias en estudios",
          repeatedMessage,
          async () => {
            this.getPriceStudyAdd(study);
          },
          () => {
            this.disabledClave = false;
            this.setFocusClave(!this.focusClave);
          }
        );
      } else {
        this.getPriceStudyAdd(study);
      }

      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      this.disabledClave = false;
      this.setFocusClave(!this.focusClave);

      return false;
    } finally {
      this.loadingTabContentCount--;
    }
  };

  private checkDuplicatesInStudies(study: IRequestStudy) {
    let isRepeated = false;
    let repeatedMessage = "";

    const activeStudies = toJS(
      this.studies.filter((x) => x.estatusId !== status.requestStudy.cancelado)
    );

    const isRepeatedStudy = activeStudies.some(
      (x) => x.estudioId === study.estudioId
    );
    if (isRepeatedStudy) {
      isRepeated = true;
      repeatedMessage = `El estudio ${study.clave} ya se encuentra agregado`;
    } else {
      const activePacks = toJS(this.packs.filter((x) => !x.cancelado));

      // prettier-ignore
      let repeatedInPacks = activePacks.filter((x) => x.estudios.some((s) => s.estudioId === study.estudioId)).map(x => x.clave);
      repeatedInPacks = getDistinct(repeatedInPacks);
      if (repeatedInPacks.length > 0) {
        isRepeated = true;
        // prettier-ignore
        repeatedMessage = `El estudio ${study.clave} ya se encuentra agregado en ${repeatedInPacks.length === 1 ? "el paquete" : "los paquetes"} ${repeatedInPacks.join(", ")}`;
      } else {
        const studyParams = study.parametros.map((x) => x.id);

        // prettier-ignore
        const repeatedParamsInStudies = activeStudies.filter((x) => x.parametros.some((p) => studyParams.includes(p.id)))
          .map((x) => ({
            ...x,
            parametros: x.parametros.filter((p) => studyParams.includes(p.id)),
          }));
        // prettier-ignore
        const repeatedParamsInPacks = activePacks.filter((x) => x.estudios.some((s) => s.parametros.some((p) => studyParams.includes(p.id))))
          .map((x) => ({
            ...x,
            estudios: x.estudios.filter((s) => s.parametros.some((p) => studyParams.includes(p.id)))
              .map((s) => ({
                ...s,
                parametros: s.parametros.filter((p) => studyParams.includes(p.id)),
              })),
          }));

        if (
          repeatedParamsInStudies.length > 0 ||
          repeatedParamsInPacks.length > 0
        ) {
          isRepeated = true;
          // prettier-ignore
          let repeatedParamsCodes = repeatedParamsInStudies.flatMap((x) => x.parametros).map((x) => x.clave);
          // prettier-ignore
          repeatedParamsCodes = repeatedParamsCodes.concat(repeatedParamsInPacks.flatMap((x) => x.estudios).flatMap((x) => x.parametros).map((x) => x.clave));
          repeatedParamsCodes = getDistinct(repeatedParamsCodes);

          const many = repeatedParamsCodes.length > 1;
          const manyStudies = repeatedParamsInStudies.length > 1;
          const manyPacks = repeatedParamsInPacks.length > 1;
          // prettier-ignore
          repeatedMessage = `${!many ? "El parametro" : "Los parametros"} ${repeatedParamsCodes.join(", ")} 
          ya se encuentra${many ? "n" : ""} agregado${many ? "s" : ""} 
          en ${repeatedParamsInStudies.length > 0 ? `estudio${manyStudies ? "s" : ""}: ${repeatedParamsInStudies.map(x => x.clave).join(",")}` : ""}
          ${repeatedParamsInStudies.length > 0 && repeatedParamsInPacks.length > 0 ? " y " : ""}
          ${repeatedParamsInPacks.length > 0 ? `paquete${manyPacks ? "s" : ""}: ${repeatedParamsInPacks.map(x => x.clave).join(",")}` : ""}`;
        }
      }
    }

    return { isRepeated, repeatedMessage };
  }

  private getPriceStudyAdd = async (study: IRequestStudy) => {
    if (
      !this.studies.map((x) => x.identificador).includes(study.identificador)
    ) {
      study.estatusId = status.requestStudy.tomaDeMuestra;
      study.estatus = statusName.requestStudy.tomaDeMuestra;
      this.studies.push(study);
      this.addStudyTag(toJS(study));

      const saveStudies = await this.updateStudies(this.studyUpdate, false);

      // if (saveStudies)
      //   await this.updateTags(
      //     this.request?.expedienteId!,
      //     this.request?.solicitudId!,
      //     false
      //   );

      this.disabledClave = false;
      this.setFocusClave(!this.focusClave);
    }
  };

  getPricePack = async (packId: number, filter: IPriceListInfoFilter) => {
    try {
      this.loadingTabContentCount++;

      filter.paqueteId = packId;
      const price = await PriceList.getPricePack(filter);

      const pack: IRequestPack = {
        ...price,
        type: "pack",
        cancelado: false,
        nuevo: true,
        asignado: true,
        descuentoManual: false,
        estudios: price.estudios.map((x) => ({
          ...x,
          type: "study",
          estatusId: status.requestStudy.tomaDeMuestra,
          estatus: statusName.requestStudy.tomaDeMuestra,
          nuevo: true,
          asignado: true,
          descuentoManual: false,
        })),
      };

      const { isRepeated, repeatedMessage } = this.checkDuplicatesInPacks(pack);

      if (isRepeated) {
        alerts.confirm(
          "Coincidencias en paquetes",
          repeatedMessage,
          async () => {
            this.getPricePackAdd(pack);
          },
          () => {
            this.disabledClave = false;
            this.setFocusClave(!this.focusClave);
          }
        );
      } else {
        this.getPricePackAdd(pack);
      }

      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      this.disabledClave = false;
      this.setFocusClave(!this.focusClave);
      return false;
    } finally {
      this.loadingTabContentCount--;
    }
  };

  private checkDuplicatesInPacks(pack: IRequestPack) {
    let isRepeated = false;
    let repeatedMessage = "";

    const activePacks = toJS(this.packs.filter((x) => !x.cancelado));

    const isRepeatedPack = activePacks.some(
      (x) => x.paqueteId === pack.paqueteId
    );
    if (isRepeatedPack) {
      isRepeated = true;
      repeatedMessage = `El paquete ${pack.clave} ya se encuentra agregado`;
    } else {
      // prettier-ignore
      let repeatedStudies = this.allActiveStudies.filter((x) => pack.estudios.some((s) => s.estudioId === x.estudioId)).map(x => x.clave);
      repeatedStudies = getDistinct(repeatedStudies);
      if (repeatedStudies.length > 0) {
        isRepeated = true;
        const many = repeatedStudies.length > 1;
        // prettier-ignore
        repeatedMessage = `${!many ? "El estudio" : "Los estudios"} ${repeatedStudies.join(",")} ya se encuentra${many ? "n" : ""} agregado${many ? "s" : ""}`;
      } else {
        // prettier-ignore
        const activeStudies = toJS(this.studies.filter((x) => x.estatusId !== status.requestStudy.cancelado));

        const packParams = pack.estudios.flatMap((x) =>
          x.parametros.map((x) => x.id)
        );

        // prettier-ignore
        const repeatedParamsInStudies = activeStudies.filter((x) => x.parametros.some((p) => packParams.includes(p.id)))
          .map((x) => ({
            ...x,
            parametros: x.parametros.filter((p) => packParams.includes(p.id)),
          }));
        // prettier-ignore
        const repeatedParamsInPacks = activePacks.filter((x) => x.estudios.some((s) => s.parametros.some((p) => packParams.includes(p.id))))
          .map((x) => ({
            ...x,
            estudios: x.estudios.filter((s) => s.parametros.some((p) => packParams.includes(p.id)))
              .map((s) => ({
                ...s,
                parametros: s.parametros.filter((p) => packParams.includes(p.id)),
              })),
          }));

        if (
          repeatedParamsInStudies.length > 0 ||
          repeatedParamsInPacks.length > 0
        ) {
          isRepeated = true;
          // prettier-ignore
          let repeatedParamsCodes = repeatedParamsInStudies.flatMap((x) => x.parametros).map((x) => x.clave);
          // prettier-ignore
          repeatedParamsCodes = repeatedParamsCodes.concat(repeatedParamsInPacks.flatMap((x) => x.estudios).flatMap((x) => x.parametros).map((x) => x.clave));
          repeatedParamsCodes = getDistinct(repeatedParamsCodes);

          const many = repeatedParamsCodes.length > 1;
          const manyStudies = repeatedParamsInStudies.length > 1;
          const manyPacks = repeatedParamsInPacks.length > 1;
          // prettier-ignore
          repeatedMessage = `${!many ? "El parametro" : "Los parametros"} ${repeatedParamsCodes.join(", ")} 
          ya se encuentra${many ? "n" : ""} agregado${many ? "s" : ""} 
          en ${repeatedParamsInStudies.length > 0 ? `estudio${manyStudies ? "s" : ""}: ${repeatedParamsInStudies.map(x => x.clave).join(",")}` : ""}
          ${repeatedParamsInStudies.length > 0 && repeatedParamsInPacks.length > 0 ? " y " : ""}
          ${repeatedParamsInPacks.length > 0 ? `paquete${manyPacks ? "s" : ""}: ${repeatedParamsInPacks.map(x => x.clave).join(",")}` : ""}`;
        }
      }
    }

    return { isRepeated, repeatedMessage };
  }

  refreshPromo = async () => {
    this.loadingTabContentCount++;
    let studiesAux: IRequestStudy[] = this.studies;
    let packsAux: IRequestPack[] = this.packs;

    this.studyFilter.estudiosIds = this.studies.map((x) => {
      return x.estudioId ?? 0;
    });
    const priceList = await PriceList.getPriceStudies(this.studyFilter);

    priceList.forEach(async (price) => {
      const list = this.studies.filter((o) => o.estudioId === price.estudioId);

      list.forEach((bs) => {
        const index = this.studies.findIndex(
          (x) => x.id === bs.id || x.identificador === bs.identificador
        );
        if (index > -1) {
          studiesAux[index].promocionId = price.promocionId;
          studiesAux[index].promocion = price.promocion;
          studiesAux[index].descuento = price.descuento;
          studiesAux[index].descuentoPorcentaje = price.descuentoPorcentaje;
          studiesAux[index].precioFinal = price.precio - (price.descuento ?? 0);
          studiesAux[index].promociones = price.promociones;
          this.studies = [...studiesAux];
        }
      });
    });
    this.studyFilter.paquetesIds = this.packs.map((x) => {
      return x.paqueteId ?? 0;
    });
    const pricePackList = await PriceList.getPricePackages(this.studyFilter);
    pricePackList.forEach(async (pack) => {
      const list = this.packs.filter((o) => o.paqueteId === pack.paqueteId);
      list.forEach((bp) => {
        const index = this.packs.findIndex(
          (x) => x.id === bp.id || x.identificador === bp.identificador
        );
        if (index > -1) {
          packsAux[index].promocionId = pack.promocionId;
          packsAux[index].promocion = pack.promocion;
          packsAux[index].descuento = pack.descuento;
          packsAux[index].descuentoPorcentaje = pack.descuentoPorcentaje;
          packsAux[index].precioFinal = pack.precio - (pack.descuento ?? 0);
          packsAux[index].promociones = pack.promociones;

          this.packs = [...packsAux];
        }
      });
    });
    this.loadingTabContentCount--;
  };

  private getPricePackAdd = async (pack: IRequestPack) => {
    if (!this.packs.map((x) => x.identificador).includes(pack.identificador)) {
      this.packs.push(pack);
      for (const study of pack.estudios) {
        this.addStudyTag(toJS(study));
      }
      const saveStudies = await this.updateStudies(this.studyUpdate, false);

      // if (saveStudies)
      //   await this.updateTags(
      //     this.request?.expedienteId!,
      //     this.request?.solicitudId!,
      //     false
      //   );

      this.disabledClave = false;
      this.setFocusClave(!this.focusClave);
    }
  };

  addStudyTag = (study: IRequestStudy) => {
    console.log("ESTUDIO ", toJS(study));
    const studyTags = study.etiquetas;
    let tags = toJS(this.tags);
    let index = 0;

    for (const studyTag of studyTags) {
      let consecutive = !!studyTags.length
        ? tags.filter((t) => t.clave.startsWith(studyTag.claveInicial)).length
        : 0;
      let existingTag = tags.find(
        (x) =>
          x.etiquetaId === studyTag.etiquetaId &&
          x.destinoId === study.destinoId
      );

      if (existingTag && !index) {
        existingTag.estudios.push({
          id: uuidv4(),
          identificador: study.identificador,
          estudioId: study.estudioId,
          claveEstudio: studyTag.claveEstudio,
          nombreEstudio: studyTag.nombreEstudio,
          orden: study.ordenEstudio,
          cantidad: studyTag.cantidad,
          asignado: true,
        });
      } else {
        const newTag: IRequestTag = {
          id: uuidv4(),
          etiquetaId: studyTag.etiquetaId,
          claveInicial: studyTag.claveInicial,
          claveEtiqueta: studyTag.claveEtiqueta,
          nombreEtiqueta: studyTag.nombreEtiqueta,
          clave: this.generateTagCode(
            studyTag.etiquetaId,
            studyTag.claveInicial,
            consecutive
          ),
          cantidad: 1,
          destinoId: study.destinoId,
          destinoTipo: study.destinoTipo,
          destino: study.destino,
          color: studyTag.color,
          temp: study.identificador,
          estudios: [
            {
              id: uuidv4(),
              identificador: study.identificador,
              estudioId: study.estudioId,
              claveEstudio: studyTag.claveEstudio,
              nombreEstudio: studyTag.nombreEstudio,
              orden: study.ordenEstudio,
              cantidad: studyTag.cantidad,
              asignado: true,
            },
          ],
        };

        tags.push(newTag);
        consecutive++;
      }
      index++;
    }

    this.tags = tags;
  };

  get missingTagStudy() {
    const studies = toJS(this.allActiveStudies).filter(
      (x) => x.etiquetas.length > 0
    );
    const missing = studies.filter(
      (x) =>
        !this.tags
          .flatMap((t) => t.estudios)
          .some(
            (s) =>
              s.solicitudEstudioId === x.id ||
              s.identificador === x.identificador
          )
    );

    return missing;
  }

  get availableTags() {
    let possibleTags: IRequestTagStudy[] = [];

    possibleTags = this.allActiveStudies.flatMap((s) =>
      s.etiquetas.map((t) => ({
        id: uuidv4(),
        solicitudEstudioId: s.id,
        identificador: s.identificador,
        estudioId: t.estudioId,
        nombreEstudio: t.nombreEstudio,
        claveEstudio: t.claveEstudio,
        etiquetaId: t.etiquetaId,
        orden: t.orden,
        asignado: true,
        cantidad: t.cantidad,
        destinoId: t.destinoId,
        destino: t.destino,
        destinoTipo: t.destinoTipo,
      }))
    );

    const tags = toJS(this.tags);

    for (const tag of tags) {
      tag.estudios = [
        ...tag.estudios,
        ...possibleTags.filter(
          (x) =>
            x.etiquetaId === tag.etiquetaId &&
            !tag.estudios.some(
              (s) =>
                (s.solicitudEstudioId === x.solicitudEstudioId ||
                  s.identificador === x.identificador) &&
                s.claveEstudio === x.claveEstudio &&
                s.nombreEstudio === x.nombreEstudio &&
                s.cantidad === x.cantidad
            )
        ),
      ];
    }

    for (const tag of tags) {
      for (const item of tag.estudios) {
        item.asignado = this.tags.flatMap((x) => x.estudios).map((x) => x.solicitudEstudioId ?? x.identificador).includes(item.solicitudEstudioId ?? item.identificador);
      }
      // prettier-ignore
    }

    return tags;
  }

  addTag = (tag: ITag) => {
    // prettier-ignore
    let consecutive = !!this.tags.length
      ? this.tags.filter((t) => t.clave.startsWith(tag.claveInicial))
          .length + 1
      : 0;
    this.tags.push({
      id: uuidv4(),
      clave: this.generateTagCode(
        tag.etiquetaId,
        tag.claveInicial,
        consecutive
      ),
      destino: "",
      destinoId: "",
      destinoTipo: 1,
      claveEtiqueta: tag.claveEtiqueta,
      nombreEtiqueta: tag.nombreEtiqueta,
      cantidad: 1,
      claveInicial: tag.claveInicial,
      color: tag.color,
      etiquetaId: tag.etiquetaId,
      observaciones: tag.observaciones,
      manual: true,
      estudios: [],
    });
  };

  deleteTag = (id: string | number) => {
    const tags = toJS(this.tags);
    const index = tags.findIndex((x) => x.id === id);

    if (index === -1) return;

    this.setTags(tags.filter((x) => x.id !== id));
  };

  sendTestEmail = async (
    recordId: string,
    requestId: string,
    email: string[]
  ) => {
    try {
      this.loadingTabContentCount++;
      await Request.sendTestEmail(recordId, requestId, email);
      alerts.info("El correo se está enviando");
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  sendTestWhatsapp = async (
    recordId: string,
    requestId: string,
    phone: string[]
  ) => {
    try {
      this.loadingTabContentCount++;
      await Request.sendTestWhatsapp(recordId, requestId, phone);
      alerts.info("El whatsapp se está enviando");
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  create = async (request: IRequest) => {
    try {
      let id = "";
      if (!request.folioWeeClinic) {
        id = await Request.create(request);
      } else {
        id = await Request.createWeeClinic(request);
      }
      return id;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  createPayment = async (request: IRequestPayment) => {
    try {
      this.loadingTabContentCount++;
      const req = new RequestPaymentClass(request);
      if (req.esTerminal && req.esPagoNetPay) {
        await this.chargeNetPayPayment(req);
      } else {
        const payment = await Request.createPayment(req);
        if (this.payments.findIndex((x) => x.id === payment.id) === -1)
          this.payments.push(payment);
        if (payment.lealtad && request.formaPagoId !== 5)
          alerts.success(messages.loyaltyWallet);
      }

      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      this.loadingTabContentCount--;
    }
  };

  private timeoutId?: ReturnType<typeof setTimeout>;

  clearLoadingNetPay = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
    this.loadingNetPay = false;
  };

  chargeNetPayPayment = async (payment: IRequestPayment) => {
    try {
      const guid = uuidv4();
      payment.notificacionId = guid;

      this.loadingTabContentCount++;
      await NetPay.paymentCharge(payment);

      this.loadingNetPay = true;
      const connection = store.notificationStore.hubConnection;
      if (!connection) return;

      if (connection.state === "Connected") {
        connection.invoke("SubscribeWithName", guid);
      }

      const notificationMethod = "NotifyNetPayPayment";

      this.timeoutId = setTimeout(() => {
        try {
          this.clearLoadingNetPay();
          connection.invoke("RemoveWithName", guid);
          connection.off(notificationMethod);
          throw new Error("No se recibió la respuesta de la terminal");
        } catch (error) {
          alerts.warning(getErrors(error));
        }
      }, 1000 * 60 * 5);

      connection.on(
        notificationMethod,
        (success: boolean, message: string, payment?: IRequestPayment) => {
          this.clearLoadingNetPay();
          connection.invoke("RemoveWithName", guid);
          connection.off(notificationMethod);

          if (!success) {
            alerts.warning(message);
            return;
          }

          if (!payment) return;

          if (this.payments.findIndex((x) => x.id === payment.id) === -1)
            this.payments = [...this.payments, payment];
        }
      );
    } catch (error) {
      alerts.warning(getErrors(error));
      this.clearLoadingNetPay();
      throw error;
    } finally {
      this.loadingTabContentCount--;
    }
  };

  reprintPayment = async (payment: IRequestPayment) => {
    try {
      this.loadingTabContentCount++;
      await this.reprintNetPayPayment(payment);
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    } finally {
      this.loadingTabContentCount--;
    }
  };

  reprintNetPayPayment = async (payment: IRequestPayment) => {
    try {
      const guid = uuidv4();
      payment.notificacionId = guid;

      this.loadingTabContentCount++;
      await NetPay.paymentReprint(payment);

      this.loadingNetPay = true;
      const connection = store.notificationStore.hubConnection;
      if (!connection) return;

      if (connection.state === "Connected") {
        connection.invoke("SubscribeWithName", guid);
      }

      const notificationMethod = "NotifyNetPayReprint";

      this.timeoutId = setTimeout(() => {
        try {
          this.clearLoadingNetPay();
          connection.invoke("RemoveWithName", guid);
          connection.off(notificationMethod);
          throw new Error("No se recibió la respuesta de la terminal");
        } catch (error) {
          alerts.warning(getErrors(error));
        }
      }, 1000 * 60 * 5);

      connection.on(notificationMethod, (success: boolean, message: string) => {
        this.clearLoadingNetPay();
        connection.invoke("RemoveWithName", guid);
        connection.off(notificationMethod);

        if (!success) {
          alerts.warning(message);
          return;
        }
      });
    } catch (error) {
      alerts.warning(getErrors(error));
      this.clearLoadingNetPay();
      throw error;
    } finally {
      this.loadingTabContentCount--;
    }
  };

  checkInPayment = async (request: IRequestCheckIn) => {
    try {
      const checkedIn = await Request.checkInPayment(request);
      this.payments = this.payments.map((payment) => {
        const updated = checkedIn.find((x) => x.id === payment.id);
        if (updated) return updated;
        return payment;
      });
      return checkedIn;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  downloadInvoicesPdf = async (request: IRequestInvoiceDownload) => {
    try {
      this.loadingTabContentCount++;
      await Request.downloadInvoicesPdf(request);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  downloadInvoicesXml = async (request: IRequestInvoiceDownload) => {
    try {
      this.loadingTabContentCount++;
      await Request.downloadInvoicesXml(request);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  updateSeries = async (request: IRequest) => {
    try {
      const seriesNumber = await Request.updateSeries(request);
      alerts.success(messages.updated);
      if (this.request) {
        this.request.serie = request.serie;
        this.request.serieNumero = seriesNumber.toString();
      }
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  updateBranch = async (request: IRequest) => {
    try {
      this.loadingTabContentCount++;
      const updatedRequest = await Request.updateBranch(request);
      if (this.request) {
        this.request = updatedRequest;
      }
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      this.loadingTabContentCount--;
    }
  };

  updateGeneral = async (request: IRequestGeneral, autoSave: boolean) => {
    try {
      if (!autoSave) this.loadingTabContentCount++;
      await Request.updateGeneral(request);
      if (!autoSave) alerts.success(messages.updated);
      if (this.request) {
        this.request.urgencia = request.urgencia;
        this.request.procedencia = request.procedencia;
      }
      // if (request.cambioCompañia) {
      //   this.clearTags();
      //   this.clearStudies();
      // }
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      if (!autoSave) this.loadingTabContentCount--;
    }
  };

  updateTotals = async (request: IRequestTotal) => {
    try {
      await Request.updateTotals(request);
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  updateStudies = async (request: IRequestStudyUpdate, autoSave: boolean) => {
    try {
      this.isUpdating = true;
      if (!autoSave) this.loadingTabContentCount++;
      const dataToUpdate: IRequestStudyUpdate = {
        ...toJS(request),
        etiquetas: toJS(
          this.tags.map((tag) => ({
            ...tag,
            id: typeof tag.id === "string" ? 0 : tag.id,
            estudios: tag.estudios.map((s) => {
              return { ...s, id: typeof s.id === "string" ? 0 : s.id };
            }),
          }))
        ),
      };

      const response = await Request.updateStudies(dataToUpdate);
      this.packs = response.paquetes ?? [];
      this.studies = response.estudios;
      this.tags = response.etiquetas ?? [];
      if (!autoSave) alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      if (!autoSave) this.loadingTabContentCount--;
      this.isUpdating = false;
    }
  };
  updateStudiesDates = async (
    solicitudId: any,
    request: IRequestStudiesDatesUpdate[]
  ) => {
    try {
      this.isUpdating = true;
      this.loadingTabContentCount++;
      await Request.updateStudiesDates(solicitudId, request);
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      this.loadingTabContentCount--;
      this.isUpdating = false;
    }
  };

  updateTags = async (
    recordId: string,
    requestId: string,
    autoSave: boolean
  ) => {
    try {
      if (this.missingTagStudy.length > 0) {
        alerts.warning("Faltan estudios por asignar");
        return false;
      }

      if (!autoSave) this.loadingTabContentCount++;
      const tags = toJS(this.tags);
      const tagsToUpdate = tags.map((tag) => ({
        ...tag,
        id: typeof tag.id === "string" ? 0 : tag.id,
        estudios: tag.estudios.map((s) => {
          if (!s.solicitudEstudioId && s.identificador) {
            // prettier-ignore
            const study = this.allActiveStudies.find((a) => a.identificador === s.identificador);
            return {
              ...s,
              id: typeof s.id === "string" ? 0 : s.id,
              solicitudEstudioId: study?.id,
            };
          }
          return { ...s, id: typeof s.id === "string" ? 0 : s.id };
        }),
      }));
      this.tags = await Request.updateTags(recordId, requestId, tagsToUpdate);
      if (!autoSave) alerts.success(messages.updatedTags);
      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      if (!autoSave) this.loadingTabContentCount--;
    }
  };

  private getDiscounts = (totalDiscount: number): number[] => {
    const noStudies =
      this.studies.filter((x) => x.estatusId !== status.requestStudy.cancelado)
        .length + this.packs.filter((x) => !x.cancelado).length;

    const individualDiscount = Math.floor(totalDiscount / noStudies);

    const remainingDiscount = totalDiscount - individualDiscount * noStudies;

    const hasDecimals = remainingDiscount % 1 !== 0;
    const decimals = !hasDecimals ? "" : (remainingDiscount % 1).toFixed(2);

    const discounts = new Array(noStudies).fill(individualDiscount);

    const remainingDiscountFloored = Math.floor(remainingDiscount);
    for (let i = 0; i < remainingDiscountFloored; i++) {
      discounts[i]++;
    }

    if (discounts.length > 0 && hasDecimals) {
      discounts[0] += +decimals;
    }

    return discounts;
  };

  // changeTotalsDiscount = async (totalDiscount: number) => {
  //   try {
  //     const discounts = this.getDiscounts(totalDiscount);

  //     for (const study of this.studies
  //       .filter((x) => x.estatusId !== status.requestStudy.cancelado)
  //       .sort((a, b) => a.precio - b.precio)) {
  //       if (discounts[0] > study.precio) {
  //         const rest = discounts[0] - study.precio;
  //         discounts[0] = study.precio;
  //         discounts[1] += rest;
  //       }
  //       this.changeStudyDiscount(study, discounts[0]);
  //       discounts.shift();
  //     }

  //     for (const pack of this.packs
  //       .filter((x) => !x.cancelado)
  //       .sort((a, b) => a.precio - b.precio)) {
  //       if (discounts[0] > pack.precio) {
  //         const rest = discounts[0] - pack.precio;
  //         discounts[0] = pack.precio;
  //         discounts[1] += rest;
  //       }
  //       this.changePackDiscount(pack, discounts[0]);
  //       discounts.shift();
  //     }

  //     const payments = this.payments.filter(
  //       (x) =>
  //         x.estatusId === status.requestPayment.pagado ||
  //         x.estatusId === status.requestPayment.facturado
  //     );

  //     const finalTotal =
  //       this.totals.totalEstudios - totalDiscount + this.totals.cargo;
  //     const balance =
  //       finalTotal - payments.reduce((acc, obj) => acc + obj.cantidad, 0);
  //     this.lastBalance = toFixedNumber(balance, 2);

  //     const totals = {
  //       ...this.totals,
  //       expedienteId: this.request!.expedienteId,
  //       solicitudId: this.request!.solicitudId!,
  //       descuento: toFixedNumber(totalDiscount, 2),
  //       cargo: this.totals.cargo,
  //       saldo: this.lastBalance,
  //       total: this.totals.totalEstudios - totalDiscount + this.totals.cargo,
  //     };
  //     this.totals = totals;

  //     await this.updateStudies(this.studyUpdate, false);
  //   } catch (error) {
  //     alerts.warning(getErrors(error));
  //   }
  // };

  changeTotalsDiscount = async (totalDiscount: number) => {
    try {
      // const discounts = this.getDiscounts(totalDiscount);
      const { totalEstudios } = this.totals;

      const discountPercentage = Number(
        (totalDiscount / totalEstudios).toFixed(4)
      );

      let totalDiscounted = 0;

      const activeStudies = this.studies.filter(
        (x) => x.estatusId !== status.requestStudy.cancelado
      );
      const activePacks = this.packs.filter((x) => !x.cancelado);

      for (const study of activeStudies) {
        totalDiscounted += this.changeStudyDiscount(study, discountPercentage);
      }

      for (const pack of activePacks) {
        totalDiscounted += this.changePackDiscount(pack, discountPercentage);
      }

      const difference = Number((totalDiscount - totalDiscounted).toFixed(2));
      if (difference !== 0) {
        const maxStudy =
          activeStudies.length === 0
            ? undefined
            : activeStudies.reduce(
                (max, item) => (item.precio > max.precio ? item : max),
                activeStudies[0]
              );
        const maxPack =
          activePacks.length === 0
            ? undefined
            : activePacks.reduce(
                (max, item) => (item.precio > max.precio ? item : max),
                activePacks[0]
              );
        const max =
          (maxStudy?.precio ?? 0) > (maxPack?.precio ?? 0) ? maxStudy : maxPack;
        if (max && this.isStudy(max)) {
          const index = this.studies.findIndex(
            (x) => (x.id ?? x.identificador) === (max.id ?? max.identificador)
          );
          if (index > -1) {
            const maxStudy = toJS(max);

            maxStudy.descuento = Number(
              (maxStudy.precio * discountPercentage + difference).toFixed(2)
            );
            maxStudy.descuentoPorcentaje = Number(
              ((maxStudy.descuento * 100) / maxStudy.precio).toFixed(2)
            );
            maxStudy.precioFinal = maxStudy.precio - (maxStudy.descuento ?? 0);
            this.studies[index].descuento = maxStudy.descuento;
            this.studies[index].descuentoPorcentaje =
              maxStudy.descuentoPorcentaje;
            this.studies[index].precioFinal = maxStudy.precioFinal;
          }
        } else if (max && this.isPack(max)) {
          const index = this.packs.findIndex(
            (x) => (x.id ?? x.identificador) === (max.id ?? max.identificador)
          );
          if (index > -1) {
            const maxPack = toJS(max);
            maxPack.descuento = Number(
              (maxPack.precio * discountPercentage + difference).toFixed(2)
            );
            maxPack.descuentoPorcentaje = Number(
              ((maxPack.descuento * 100) / maxPack.precio).toFixed(2)
            );
            maxPack.precioFinal = maxPack.precio - (maxPack.descuento ?? 0);
            this.packs[index].descuento = maxPack.descuento;
            this.packs[index].descuentoPorcentaje = maxPack.descuentoPorcentaje;
            this.packs[index].precioFinal = maxPack.precioFinal;
          }
        }
      }

      const payments = this.payments.filter(
        (x) =>
          x.estatusId === status.requestPayment.pagado ||
          x.estatusId === status.requestPayment.facturado
      );

      const finalTotal =
        this.totals.totalEstudios - totalDiscount + this.totals.cargo;
      const balance =
        finalTotal - payments.reduce((acc, obj) => acc + obj.cantidad, 0);
      this.lastBalance = toFixedNumber(balance, 2);

      const totals = {
        ...this.totals,
        expedienteId: this.request!.expedienteId,
        solicitudId: this.request!.solicitudId!,
        descuento: toFixedNumber(totalDiscount, 2),
        cargo: this.totals.cargo,
        saldo: this.lastBalance,
        total: this.totals.totalEstudios - totalDiscount + this.totals.cargo,
      };
      this.totals = totals;

      await this.updateStudies(this.studyUpdate, false);
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  // changeStudyDiscount = (study: IRequestStudy, discount: number) => {
  //   const index = this.studies.findIndex(
  //     (x) => (x.id ?? x.identificador) === (study.id ?? study.identificador)
  //   );
  //   if (index > -1) {
  //     const _study: IRequestStudy = toJS(study);
  //     _study.descuentoManual = true;
  //     if (!_study.descuentoModificado) {
  //       _study.descuentoOriginal = _study.descuento;
  //       _study.promocionOriginal = _study.promocion;
  //       _study.promocionIdOriginal = _study.promocionId;
  //     }
  //     _study.promocionId = undefined;
  //     _study.promocion = undefined;
  //     _study.descuento = discount;
  //     _study.descuentoPorcentaje = Number(((discount * 100) / _study.precio).toFixed(2));
  //     _study.precioFinal = _study.precio - (_study.descuento ?? 0);
  //     _study.descuentoModificado = true;

  //     this.studies[index] = _study;
  //   }
  // };

  changeStudyDiscount = (study: IRequestStudy, discount: number) => {
    const index = this.studies.findIndex(
      (x) => (x.id ?? x.identificador) === (study.id ?? study.identificador)
    );
    if (index > -1) {
      const _study: IRequestStudy = toJS(study);
      _study.descuentoManual = true;
      if (!_study.descuentoModificado) {
        _study.descuentoOriginal = _study.descuento;
        _study.promocionOriginal = _study.promocion;
        _study.promocionIdOriginal = _study.promocionId;
      }
      _study.promocionId = undefined;
      _study.promocion = undefined;
      _study.descuento = Number((_study.precio * discount).toFixed(2));
      _study.descuentoPorcentaje = discount * 100;
      _study.precioFinal = _study.precio - (_study.descuento ?? 0);
      _study.descuentoModificado = true;

      this.studies[index] = _study;

      return _study.descuento;
    }

    return 0;
  };

  changeStudyDiscountSingle = (study: IRequestStudy, discount: number) => {
    const index = this.studies.findIndex(
      (x) => (x.id ?? x.identificador) === (study.id ?? study.identificador)
    );
    if (index > -1) {
      const _study: IRequestStudy = toJS(study);
      _study.descuentoManual = true;
      if (!_study.descuentoModificado) {
        _study.descuentoOriginal = _study.descuento;
        _study.promocionOriginal = _study.promocion;
        _study.promocionIdOriginal = _study.promocionId;
      }
      _study.promocionId = undefined;
      _study.promocion = undefined;
      _study.descuento = Number(discount.toFixed(2)); // Number((_study.precio * discount).toFixed(2));
      _study.descuentoPorcentaje = Number(
        ((discount * 100) / _study.precio).toFixed(2)
      );
      _study.precioFinal = _study.precio - (_study.descuento ?? 0);
      _study.descuentoModificado = true;

      this.studies[index] = _study;

      return _study.descuento;
    }

    return 0;
  };

  revertDiscountStudy = () => {
    this.studies = this.studies.map((x) => {
      if (x.descuentoModificado) {
        x.promocionId = x.promocionIdOriginal;
        x.promocion = x.promocionOriginal;
        x.descuento = x.descuentoOriginal;
        x.descuentoPorcentaje = Number(
          ((x.descuento! * 100) / x.precio).toFixed(2)
        );
        x.precioFinal = x.precio - (x.descuento ?? 0);
        x.descuentoModificado = false;
      }
      return x;
    });
  };

  changeStudyPromotion = (study: IRequestStudy, promoId?: number) => {
    const index = this.studies.findIndex(
      (x) => (x.id ?? x.identificador) === (study.id ?? study.identificador)
    );
    if (index > -1) {
      const _study = { ...this.studies[index] };
      const promo = this.studies[index].promociones.find(
        (x) => x.promocionId === promoId
      );
      this.studies[index] = {
        ..._study,
        promocionId: promoId,
        promocion: promo?.promocion,
        descuento: promo?.descuento,
        descuentoPorcentaje: promo?.descuentoPorcentaje,
        precioFinal: _study.precio - (promo?.descuento ?? 0),
        descuentoModificado: true,
      };
    }
  };

  // changePackDiscount = (pack: IRequestPack, discount: number) => {
  //   const index = this.packs.findIndex(
  //     (x) => (x.id ?? x.identificador) === (pack.id ?? pack.identificador)
  //   );
  //   if (index > -1) {
  //     const _pack: IRequestPack = toJS(pack);

  //     if (!_pack.descuentoModificado) {
  //       _pack.descuentoOriginal = _pack.descuento;
  //       _pack.promocionIdOriginal = _pack.promocionId;
  //       _pack.promocionOriginal = _pack.promocion;
  //     }
  //     _pack.promocionId = undefined;
  //     _pack.promocion = undefined;
  //     _pack.descuento = discount;
  //     _pack.descuentoPorcentaje = Number(((discount * 100) / _pack.precio).toFixed(2));
  //     _pack.precioFinal = _pack.precio - (_pack.descuento ?? 0);
  //     _pack.descuentoModificado = true;

  //     this.packs[index] = _pack;
  //   }
  // };

  changePackDiscount = (pack: IRequestPack, discount: number) => {
    const index = this.packs.findIndex(
      (x) => (x.id ?? x.identificador) === (pack.id ?? pack.identificador)
    );
    if (index > -1) {
      const _pack: IRequestPack = toJS(pack);

      _pack.descuentoManual = true;
      if (!_pack.descuentoModificado) {
        _pack.descuentoOriginal = _pack.descuento;
        _pack.promocionOriginal = _pack.promocion;
        _pack.promocionIdOriginal = _pack.promocionId;
      }
      _pack.promocionId = undefined;
      _pack.promocion = undefined;
      _pack.descuento = Number((_pack.precio * discount).toFixed(2));
      _pack.descuentoPorcentaje = discount * 100;
      _pack.precioFinal = _pack.precio - (_pack.descuento ?? 0);
      _pack.descuentoModificado = true;

      this.packs[index] = _pack;

      return _pack.descuento;
    }

    return 0;
  };

  changePackDiscountSingle = (pack: IRequestPack, discount: number) => {
    const index = this.packs.findIndex(
      (x) => (x.id ?? x.identificador) === (pack.id ?? pack.identificador)
    );
    if (index > -1) {
      const _pack: IRequestPack = toJS(pack);

      _pack.descuentoManual = true;
      if (!_pack.descuentoModificado) {
        _pack.descuentoOriginal = _pack.descuento;
        _pack.promocionOriginal = _pack.promocion;
        _pack.promocionIdOriginal = _pack.promocionId;
      }
      _pack.promocionId = undefined;
      _pack.promocion = undefined;
      _pack.descuento = Number(discount.toFixed(2)); // Number((_study.precio * discount).toFixed(2));
      _pack.descuentoPorcentaje = Number(
        ((discount * 100) / _pack.precio).toFixed(2)
      );
      _pack.precioFinal = _pack.precio - (_pack.descuento ?? 0);
      _pack.descuentoModificado = true;

      this.packs[index] = _pack;

      return _pack.descuento;
    }

    return 0;
  };

  revertDiscountPack = () => {
    this.packs = this.packs.map((x) => {
      if (x.descuentoModificado) {
        x.descuentoManual = false;
        x.promocionId = x.promocionIdOriginal;
        x.promocion = x.promocionOriginal;
        x.descuento = x.descuentoOriginal;
        x.descuentoPorcentaje = Number(
          ((x.descuento! * 100) / x.precio).toFixed(2)
        );
        x.precioFinal = x.precio + (x.descuento ?? 0);
        x.descuentoModificado = false;
      }
      return x;
    });
  };

  changePackPromotion = (pack: IRequestPack, promoId?: number) => {
    const index = this.packs.findIndex(
      (x) => (x.id ?? x.identificador) === (pack.id ?? pack.identificador)
    );
    if (index > -1) {
      const _pack = { ...this.packs[index] };
      const promo = this.packs[index].promociones.find(
        (x) => x.promocionId === promoId
      );
      this.packs[index] = {
        ..._pack,
        promocionId: promoId,
        promocion: promo?.promocion,
        descuento: promo?.descuento,
        descuentoPorcentaje: promo?.descuentoPorcentaje,
        precioFinal: _pack.precio - (promo?.descuento ?? 0),
        descuentoModificado: true,
      };
    }
  };

  changeTotalsCharge = async (totalCharge: number) => {
    try {
      const totals = {
        ...this.totals,
        expedienteId: this.request!.expedienteId,
        solicitudId: this.request!.solicitudId!,
        descuento: this.totals.descuento,
        cargo: toFixedNumber(totalCharge, 2),
        saldo: toFixedNumber(this.lastBalance + totalCharge, 2),
        total: toFixedNumber(
          this.totals.totalEstudios - this.totals.descuento + totalCharge,
          2
        ),
      };
      await Request.updateTotals(totals);
      this.totals = totals;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  deleteStudy = async (id: string) => {
    this.studies = this.studies.filter((x) => x.identificador !== id);

    this.deleteTags([id], "identificador");
  };

  deletePack = async (id: number | string) => {
    const pack = toJS(this.packs.find((x) => x.identificador === id));
    this.packs = this.packs.filter((x) => x.identificador !== id);

    if (pack) {
      this.deleteTags(
        pack.estudios.map((x) => x.identificador!),
        "identificador"
      );
    }
  };

  private deleteTags = (
    ids: (string | number)[],
    key: keyof IRequestTagStudy
  ) => {
    let tags = toJS(this.tags);
    let tagsToDelete: (number | string)[] = [];

    for (const id of ids) {
      // prettier-ignore
      for (const tag of tags.filter((x) => x.estudios.map((x) => x[key]).includes(id))) {
        tag.estudios = tag.estudios.filter((x) => x[key] !== id);
        if (tag.estudios.length === 0) {
          tagsToDelete.push(tag.id);
        }
      }
    }
    tags = tags.filter((x) => !tagsToDelete.includes(x.id));

    this.tags = tags;
  };

  cancelRequest = async (recordId: string, requestId: string) => {
    try {
      await Request.cancelRequest(recordId, requestId);
      if (this.request) this.request.estatusId = status.request.cancelado;
      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  deleteCurrentRequest = async () => {
    try {
      if (this.request?.expedienteId && this.request?.solicitudId) {
        await Request.deleteRequest(
          this.request.expedienteId,
          this.request.solicitudId
        );
        this.requests = this.requests.filter(
          (x) =>
            x.expedienteId !== this.request?.expedienteId &&
            x.solicitudId !== this.request?.solicitudId
        );
        return true;
      }
      alerts.warning("No hay solicitud por eliminar");
      return false;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  cancelPayment = async (
    recordId: string,
    requestId: string,
    pay: IRequestPayment
  ) => {
    try {
      this.loadingTabContentCount++;
      pay.expedienteId = recordId;
      pay.solicitudId = requestId;
      const req = new RequestPaymentClass(pay);
      if (req.esTerminal && req.esPagoNetPay) {
        await this.cancelNetPayPayment(pay);
      } else {
        const cancelled = await Request.cancelPayment(recordId, requestId, req);
        this.payments = [
          ...this.payments.filter((x) => x.id !== cancelled.id),
          cancelled,
        ];
      }
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    } finally {
      this.loadingTabContentCount--;
    }
  };

  cancelPaymentInvoice = async (
    recordId: string,
    requestId: string,
    pay: IRequestPayment
  ) => {
    try {
      this.loadingTabContentCount++;

      // prettier-ignore
      const invoice = pay.facturas?.find((x) => x.estatus === status.invoice.facturado);
      if (!invoice) return;

      await Request.cancelPaymentInvoice(recordId, requestId, invoice);
      this.payments = this.payments.map((payment) => {
        if (payment.facturas?.some((x) => x.facturaId === invoice.facturaId)) {
          payment.estatusId = status.requestPayment.pagado;
          payment.facturas = payment.facturas.map((x) => ({
            ...x,
            estatus: status.invoice.cancelado,
          }));
          return payment;
        } else {
          return payment;
        }
      });
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  cancelNetPayPayment = async (payment: IRequestPayment) => {
    try {
      const guid = uuidv4();
      payment.notificacionId = guid;

      this.loadingTabContentCount++;
      await NetPay.paymentCancel(payment);

      this.loadingNetPay = true;
      const connection = store.notificationStore.hubConnection;
      if (!connection) return;

      if (connection.state === "Connected") {
        connection.invoke("SubscribeWithName", guid);
      }

      const notificationMethod = "NotifyNetPayCancel";

      this.timeoutId = setTimeout(() => {
        try {
          this.clearLoadingNetPay();
          connection.invoke("RemoveWithName", guid);
          connection.off(notificationMethod);
          throw new Error("No se recibió la respuesta de la terminal");
        } catch (error) {
          alerts.warning(getErrors(error));
        }
      }, 1000 * 60 * 5);

      connection.on(
        notificationMethod,
        (success: boolean, message: string, payment?: IRequestPayment) => {
          this.clearLoadingNetPay();
          connection.invoke("RemoveWithName", guid);
          connection.off(notificationMethod);

          if (!success) {
            alerts.warning(message);
            return;
          }

          if (!payment) return;

          this.payments = [
            ...this.payments.filter((x) => x.id !== payment.id),
            payment,
          ];
        }
      );
    } catch (error) {
      alerts.warning(getErrors(error));
      this.clearLoadingNetPay();
      throw error;
    } finally {
      this.loadingTabContentCount--;
    }
  };

  cancelStudies = async (request: IRequestStudyUpdate) => {
    try {
      const updatedStudies = await Request.cancelStudies(request);
      this.packs = this.packs.map((x) => {
        const cancelled = request.paquetes?.find((p) => p.id === x.id);
        return {
          ...x,
          cancelado: x.cancelado || !!cancelled,
        };
      });
      this.updateSudies(updatedStudies);
      // prettier-ignore
      this.deleteTags(updatedStudies.map((x) => x.id!), "solicitudEstudioId");
      this.updateTags(request.expedienteId, request.solicitudId, false);
      // await this.getTags(request.expedienteId, request.solicitudId);
      // this.updateTagsStudy();
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  sendStudiesToSampling = async (request: IRequestStudyUpdate) => {
    try {
      const updatedStudies = await Request.sendStudiesToSampling(request);
      this.updateSudies(updatedStudies);
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  sendStudiesToRequest = async (request: IRequestStudyUpdate) => {
    try {
      const updatedStudies = await Request.sendStudiesToRequest(request);
      this.updateSudies(updatedStudies);
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  addPartiality = async (request: IRequestPartiality) => {
    try {
      await Request.addPartiality(request);
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  printTicket = async (recordId: string, requestId: string) => {
    try {
      this.loadingTabContentCount++;
      await Request.printTicket(recordId, requestId);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  getOrderPdfUrl = async (recordId: string, requestId: string) => {
    try {
      const url = await Request.getOrderPdfUrl(recordId, requestId);
      return url;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  printTags = async (
    recordId: string,
    requestId: string,
    tags: IRequestTag[]
  ) => {
    try {
      await Request.printTags(recordId, requestId, tags);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  printIndications = async (recordId: string, requestId: string) => {
    try {
      this.loadingTabContentCount++;
      await Request.printIndications(recordId, requestId);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingTabContentCount--;
    }
  };

  saveImage = async (request: FormData) => {
    try {
      var imageName = await Request.saveImage(request);
      return imageName;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  deleteImage = async (recordId: string, requestId: string, code: string) => {
    try {
      await Request.deleteImage(recordId, requestId, code);
      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  sendWeeToken = async (request: IRequestToken) => {
    try {
      const data = await Request.sendWeeToken(request);
      return data;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  compareWeeToken = async (request: IRequestToken) => {
    try {
      const data = await Request.compareWeeToken(request);
      return data;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  verifyWeeToken = async (request: IRequestToken) => {
    try {
      const data = await Request.verifyWeeToken(request);
      return data;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  assignWeeServices = async (recordId: string, requestId: string) => {
    try {
      const data = await Request.assignWeeServices(recordId, requestId);
      return data;
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  // prettier-ignore
  private calculateTotals = () => {
    const studies = this.studies.filter((x) => x.estatusId !== status.requestStudy.cancelado && x.asignado);
    const packs = this.packs.filter((x) => !x.cancelado && x.asignado);

    const payments = this.payments.filter((x) =>
      x.estatusId === status.requestPayment.pagado ||
      x.estatusId === status.requestPayment.facturado
    );

    const studyAndPack = studies.map((x) => ({ descuento: x.descuento ?? 0, precio: x.precio, precioFinal: x.precioFinal, copago: x.copago ?? 0 }))
      .concat(packs.map((x) => ({ descuento: x.descuento ?? 0, precio: x.precio, precioFinal: x.precioFinal, copago: 0 })));

    const totalStudies = studyAndPack.reduce((acc, obj) => acc + obj.precio, 0);

    let discount = 0;
    // if (this.totals.descuento === 0)//ya hay un descuento manual
    discount = totalStudies === 0 ? 0 : studyAndPack.reduce((acc, obj) => acc + (obj.descuento ?? 0), 0);
    // else
    // discount = this.totals.descuento;

    discount = Math.max(discount, studyAndPack.reduce((acc, obj) => acc + (obj.descuento ?? 0), 0))
    const charge = this.totals.cargo; //totalStudies === 0 ? 0 : this.request?.urgencia === catalog.urgency.urgenteCargo ? totalStudies * 0.1 : 0;
    // const cup = totalStudies === 0 ? 0 : this.request?.esWeeClinic ? studyAndPack.reduce((acc, obj) => acc + obj.copago, 0)
    //   : this.request?.procedencia === catalog.origin.convenio ? payments.reduce((acc, obj) => acc + obj.cantidad, 0) : 0;
    const cup = totalStudies === 0 ? 0
      : this.request?.procedencia === catalog.origin.particular ? 0
        : this.request?.esWeeClinic ? studyAndPack.reduce((acc, obj) => acc + obj.copago, 0)
          : payments.filter(x => !x.pagoCompañia).reduce((acc, obj) => acc + obj.cantidad, 0);

    const finalTotal = totalStudies - discount + charge;
    // const userTotal = cup > 0 ? cup : finalTotal;
    const userTotal = finalTotal;
    const balance = finalTotal - payments.reduce((acc, obj) => acc + obj.cantidad, 0);
    this.lastBalance = balance;

    this.totals = {
      ...this.totals,
      totalEstudios: toFixedNumber(totalStudies, 2),
      descuento: toFixedNumber(discount, 2),
      cargo: toFixedNumber(charge, 2),
      copago: toFixedNumber(cup, 2),
      total: toFixedNumber(userTotal, 2),
      saldo: toFixedNumber(balance, 2),
      pagos: toFixedNumber(payments.reduce((acc, obj) => acc + obj.cantidad, 0), 2)
    };
  };

  private updateSudies = (updatedStudies: IRequestStudy[]) => {
    this.studies = this.studies.map((s) => {
      const upd = updatedStudies.find((u) => u.id === s.id);
      if (upd) {
        return upd;
      }
      return s;
    });

    this.packs = this.packs.map((p) => {
      p.estudios = p.estudios.map((s) => {
        const upd = updatedStudies.find((u) => u.id === s.id);
        if (upd) {
          return upd;
        }
        return s;
      });
      return p;
    });
  };

  private generateTagCode = (
    tagId: number,
    initialCode: string,
    consecutive: number | null = null
  ) => {
    if (!this.request) return "";

    // prettier-ignore
    const final = initialCode
      ? initialCode
      : "";

    const count =
      consecutive != null
        ? consecutive
        : this.tags.filter((t) => t.clave.startsWith(final)).length;

    return (
      final +
      moment(this.request.registro, "DD/MM/YYYY HH:mm")
        .utcOffset(0, true)
        .format("MMDD") +
      count.toString() +
      (this.request.clave || "").slice(-5)
    );
  };
}
