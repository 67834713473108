import { Spin, Form, Row, Col, Pagination, Button } from "antd";
import { FC, useEffect, useState } from "react";
import { formItemLayout } from "../../../app/util/utils";
import TextInput from "../../../app/common/form/TextInput";
import { useStore } from "../../../app/stores/store";
import { ITagForm } from "../../../app/models/tag";
import { useNavigate, useSearchParams } from "react-router-dom";
import ImageButton from "../../../app/common/button/ImageButton";
import { observer } from "mobx-react-lite";
import views from "../../../app/util/view";
import SwitchInput from "../../../app/common/form/SwitchInput";
import alerts from "../../../app/util/alerts";
import messages from "../../../app/util/messages";
import TagStudies from "./TagStudies";

type TagFormProps = {
  id: number;
};

const TagForm: FC<TagFormProps> = ({ id }) => {
  const { tagStore } = useStore();
  const { scopes, tags, getById, getAll, create, update } = tagStore;

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [form] = Form.useForm<ITagForm>();

  const [loading, setLoading] = useState(false);
  const [readonly, setReadonly] = useState(
    searchParams.get("mode") === "readonly" || (!!id && !scopes.modificar)
  );
  const [currentTag, setCurrentTag] = useState<ITagForm>();

  useEffect(() => {
    const readTag = async (id: number) => {
      setLoading(true);
      const tag = await getById(id);
      setLoading(false);

      form.setFieldsValue(tag!);
      setCurrentTag(tag!);
    };

    if (id) {
      readTag(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (tags.length === 0) {
      getAll(searchParams.get("search") ?? "all");
    }
  }, [getAll, tags.length, searchParams]);

  const onFinish = async (newValues: ITagForm) => {
    setLoading(true);

    const tag = { ...(currentTag ?? {}), ...newValues };

    let success = false;

    if (!tag.id) {
      success = await create(tag);
    } else {
      success = await update(tag);
    }

    setLoading(false);

    if (success) {
      goBack();
    }
  };

  const goBack = () => {
    searchParams.delete("mode");
    setSearchParams(searchParams);
    navigate(`/${views.tag}?${searchParams}`);
  };

  const setEditMode = () => {
    searchParams.delete("mode");
    setSearchParams(searchParams);
    navigate(`/${views.tag}/${id}?${searchParams}&mode=edit`);
    setReadonly(false);
  };

  const getPage = (id: number) => {
    return tags.findIndex((x) => x.id === id) + 1;
  };

  const setPage = (page: number) => {
    const tag = tags[page - 1];
    navigate(`/${views.tag}/${tag.id}?${searchParams}`);
  };

  return (
    <Spin spinning={loading}>
      <Row style={{ marginBottom: 24 }}>
        {!!id && (
          <Col md={12} sm={24} xs={12} style={{ textAlign: "left" }}>
            <Pagination
              simple
              key="pagination"
              size="small"
              total={tags?.length ?? 0}
              pageSize={1}
              current={getPage(id)}
              onChange={setPage}
              showSizeChanger={false}
            />
          </Col>
        )}
        {!readonly && (scopes.crear || scopes.modificar) && (
          <Col md={id ? 12 : 24} sm={24} xs={12} style={{ textAlign: "right" }}>
            <Button onClick={goBack}>Cancelar</Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                form.submit();
              }}
            >
              Guardar
            </Button>
          </Col>
        )}
        {readonly && scopes.modificar && (
          <Col md={12} sm={24} xs={12} style={{ textAlign: "right" }}>
            <ImageButton
              key="edit"
              title="Editar"
              image="editar"
              onClick={setEditMode}
            />
          </Col>
        )}
      </Row>
      <Form<ITagForm>
        {...formItemLayout}
        form={form}
        name="tag"
        initialValues={{
          activo: true,
        }}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row>
          <Col md={12} sm={24} xs={12}>
            <TextInput
              formProps={{
                name: "clave",
                label: "Clave",
              }}
              max={100}
              required
              readonly={readonly}
            />
          </Col>
          <Col md={12} sm={24} xs={12}></Col>
          <Col md={12} sm={24} xs={12}>
            <TextInput
              formProps={{
                name: "nombre",
                label: "Nombre",
              }}
              max={100}
              required
              readonly={readonly}
            />
          </Col>
          <Col md={12} sm={24} xs={12}></Col>
          <Col md={12} sm={24} xs={12}>
            <TextInput
              formProps={{
                name: "claveInicial",
                label: "Clave inicial",
              }}
              max={100}
              required
              readonly={readonly}
            />
          </Col>
          <Col md={12} sm={24} xs={12}></Col>
          <Col md={12} sm={24} xs={12}>
            <SwitchInput
              name="activo"
              onChange={(value) => {
                if (value) {
                  alerts.info(messages.confirmations.enable);
                } else {
                  alerts.info(messages.confirmations.disable);
                }
              }}
              label="Activo"
              readonly={readonly}
            />
          </Col>
        </Row>
      </Form>
      {currentTag?.estudios && currentTag.estudios.length > 0 && (
        <TagStudies studies={currentTag.estudios} />
      )}
    </Spin>
  );
};

export default observer(TagForm);
