import { PageHeader, Pagination, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import HeaderTitle from "../../../app/common/header/HeaderTitle";
import { useStore } from "../../../app/stores/store";
import views from "../../../app/util/view";
import { IAppointmentInfo } from "../../../app/models/appointment";

const { Text } = Typography;

const AppointmentHeader = () => {
  const { profileStore, appointmentStore, generalStore } = useStore();
  const { profile } = profileStore;
  const { typeFilter, setType, appointment, appointments, getAppointments } =
    appointmentStore;
  const { generalFilter } = generalStore;

  let navigate = useNavigate();

  const { appointmentId } = useParams();

  const [typeAppointments, setTypeAppointments] = useState<IAppointmentInfo[]>(
    []
  );

  useEffect(() => {
    if (appointment && typeFilter !== appointment.tipo) {
      setType(appointment.tipo);
    }
  }, [appointment, setType, typeFilter]);

  useEffect(() => {
    setTypeAppointments(appointments.filter((x) => x.tipo === typeFilter));
  }, [typeFilter, appointments]);

  useEffect(() => {
    if (
      appointments.length === 0 ||
      appointments.findIndex((x) => x.citaId === appointmentId) === -1
    ) {
      const filter = { ...generalFilter, sucursalId: [profile!.sucursal] };
      getAppointments(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);

  const getPage = (id?: string) => {
    return typeAppointments.findIndex((x) => x.citaId === id) + 1;
  };

  const setPage = (page: number) => {
    const appointment = typeAppointments[page - 1];
    navigate(`/${views.appointment}/${appointment.citaId}`);
  };

  const getBack = () => {
    navigate(`/${views.appointment}?type=${appointment?.tipo}`);
  };

  return (
    <PageHeader
      ghost={false}
      title={<HeaderTitle title="Cita" />}
      onBack={getBack}
      className="header-container"
      subTitle={
        <Pagination
          simple
          key="pagination"
          size="small"
          total={typeAppointments.length ?? 0}
          pageSize={1}
          current={getPage(appointment?.citaId)}
          onChange={setPage}
          showSizeChanger={false}
        />
      }
      extra={[
        <Text key="type" strong>
          Cita {appointment?.tipo === "L" ? "en laboratorio" : "a domicilio"}
        </Text>,
        <Text key="appointment">
          Cita: <Text strong>{appointment?.clave}</Text>
        </Text>,
        <Text key="number">
          Registro: <Text strong>{appointment?.registro}</Text>
        </Text>,
      ]}
    ></PageHeader>
  );
};

export default observer(AppointmentHeader);
