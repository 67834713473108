import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  PageHeader,
  Pagination,
  Row,
  Space,
  Spin,
  Table,
  TreeSelect,
} from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { VList } from "virtual-table-ant-design";

import ImageButton from "../../../app/common/button/ImageButton";
import NumberInput from "../../../app/common/form/proposal/NumberInput";
import SelectInput from "../../../app/common/form/proposal/SelectInput";
import SwitchInput from "../../../app/common/form/proposal/SwitchInput";
import TextAreaInput from "../../../app/common/form/proposal/TextAreaInput";
import TextInput from "../../../app/common/form/proposal/TextInput";
import TimeInput from "../../../app/common/form/proposal/TimeInput";
import HeaderTitle from "../../../app/common/header/HeaderTitle";
import {
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../app/common/table/utils";
import {
  IDias,
  IRouteForm,
  IStudyRouteList,
  RouteFormValues,
} from "../../../app/models/route";
import { useStore } from "../../../app/stores/store";
import alerts from "../../../app/util/alerts";
import { daysOfWeek } from "../../../app/util/catalogs";
import messages from "../../../app/util/messages";
import { formItemLayout } from "../../../app/util/utils";
import views from "../../../app/util/view";
import useWindowDimensions from "../../../app/util/window";

type RouteFormProps = {
  id: string;
  printing: boolean;
};

type UrlParams = {
  id: string;
};

const RouteForm: FC<RouteFormProps> = ({ printing }) => {
  const { optionStore, routeStore, profileStore } = useStore();
  const { routes, getById, getAll, create, update, getAllStudy, scopes } =
    routeStore;
  const {
    BranchOptions,
    getAllBranchOptions,
    DeliveryOptions,
    getDeliveryOptions,
    MaquiladorOptions,
    getMaquiladorOptions,
    areaOptions,
    departmentOptions,
    getAreaOptions,
    getDepartmentOptions,
  } = optionStore;
  const { profile } = profileStore;

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm<IRouteForm>();
  const [formAreaByDepartment] = Form.useForm<any>();

  const [routeStudies, setRouteStudies] = useState<IStudyRouteList[]>([]);
  const [initialSelected, setInitialSelected] = useState<number[]>([]);
  const [newSelected, setNewSelected] = useState<number[]>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [readonly, setReadonly] = useState(
    searchParams.get("mode") === "readonly"
  );
  const [values, setValues] = useState<IRouteForm>(new RouteFormValues());
  let { id } = useParams<UrlParams>();
  const { width: windowWidth } = useWindowDimensions();
  const [selectedTags, setSelectedTags] = useState<IDias[]>([]);
  const [timeType, setTimeType] = useState<number>(1);

  const departmentId = Form.useWatch("departamentoId", formAreaByDepartment);
  const areaId = Form.useWatch("areaId", formAreaByDepartment);
  const code = Form.useWatch("buscar", formAreaByDepartment);

  useEffect(() => {
    const getStudies = async () => {
      setLoading(true);
      let study = await getAllStudy();
      if (study) {
        setRouteStudies(study);
        form.setFieldValue("activo", true);
      }
      setLoading(false);
    };
    if (!id) {
      getStudies();
    }
  }, [getAllStudy]);

  useEffect(() => {
    getAllBranchOptions();
    getDeliveryOptions();
    getMaquiladorOptions();
    getDepartmentOptions();
  }, [
    getAllBranchOptions,
    getDeliveryOptions,
    getMaquiladorOptions,
    getDepartmentOptions,
  ]);

  useEffect(() => {
    if (departmentId) getAreaOptions(departmentId);
  }, [departmentId, getAreaOptions]);

  useEffect(() => {
    const readRoute = async (routeId: string) => {
      setLoading(true);
      let studies = await getAllStudy();
      const route = await getById(routeId);

      if (route) {
        if (route.tipoTiempo === 0) route.tipoTiempo = 1;

        setTimeType(route.tipoTiempo ?? 1);

        if (studies) {
          studies = studies?.map((x) => {
            let activo = route?.estudio.find((y) => y.id === x.id) != null;
            return { ...x, activo };
          });

          setSelectedRowKeys(
            studies?.filter((x) => x.activo)?.map((x) => x.id) ?? []
          );

          const tableStudies = [...studies, ...route.estudio];
          setValues(route);
          setRouteStudies(
            tableStudies.filter((item, index) => {
              return tableStudies.findIndex((x) => x.id === item.id) === index;
            })
          );

          if (route && route.estudio) {
            setInitialSelected(route.estudio.map((x) => x.id));
          }
        }

        if (route.destinoId || route.maquiladorId) {
          const branch = treeData
            .map((x) => x.children)
            .flat()
            .find((x) => x.value === route.destinoId);
          const maquila = treeData
            .map((x) => x.children)
            .flat()
            .find((x) => x.value === route.maquiladorId);

          if (branch) {
            route.destinoId = branch.value as string;
          }
          if (maquila) {
            route.destinoId = maquila.value as string;
          }
        }

        route.horaDeRecoleccion = moment(route.horaDeRecoleccion);
      }

      setLoading(false);
      setSelectedTags(route?.dias!);
      form.setFieldsValue(route!);

      setLoading(false);
    };
    if (id) {
      readRoute(id);
    } else {
      form.setFieldValue("origenId", profile?.sucursal);
    }
  }, [form, getById, id]);

  const onFinish = async (newValues: IRouteForm) => {
    setLoading(true);
    const route = { ...values, ...newValues };

    let newRouteStudies = routeStudies.filter((x) =>
      selectedRowKeys.includes(x.id)
    );

    if (newRouteStudies.length === 0) {
      setLoading(false);
      return alerts.warning("Debe seleccionar estudios para esta ruta.");
    }

    route.estudio = newRouteStudies.map((x) => ({
      ...x,
      nuevo: newSelected.includes(x.id),
    }));
    route.dias = selectedTags;

    const harvest = moment(newValues.horaDeRecoleccion).utcOffset(0, true);

    if (harvest.hour() < 7 || harvest.hour() > 19) {
      setLoading(false);
      return alerts.warning("El horario de recolección debe ser hábil.");
    }

    route.horaDeRecoleccion = harvest.toDate();

    const parent = treeData.find((x) =>
      x.children.map((x) => x.value).includes(newValues.destinoId!)
    );

    if (parent?.title === "Sucursales") {
      route.maquiladorId = undefined;
    } else {
      route.maquiladorId = newValues.destinoId;
      route.destinoId = undefined;
    }
    let success = false;
    if (!route.id) {
      success = await create(route);
    } else {
      success = await update(route);
    }
    setLoading(false);
    if (success) {
      goBack();
      await getAll("all");
    }
  };

  const goBack = () => {
    searchParams.delete("mode");
    setSearchParams(searchParams);
    navigate(`/${views.route}?${searchParams}`);
  };

  const setEditMode = () => {
    navigate(`/${views.route}/${id}?${searchParams}&mode=edit`);
    setReadonly(false);
  };

  const getPage = (id: string) => {
    return routes.findIndex((x) => x.id === id) + 1;
  };

  const setPage = (page: number) => {
    const route = routes[page - 1];
    navigate(`/${views.route}/${route.id}?${searchParams}`);
  };

  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const handleChange = (tag: IDias, checked: Boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags!, tag]
      : selectedTags.filter((t) => t.id !== tag.id);
    setSelectedTags(nextSelectedTags!);
  };

  const treeData = [
    {
      title: "Sucursales",
      value: "destinoId",
      children: BranchOptions.map((x) => ({
        title: x.label,
        value: x.value,
      })),
    },
    {
      title: "Maquiladores",
      value: "maquiladorId",
      children: MaquiladorOptions.map((x) => ({
        title: x.label,
        value: x.value,
      })),
    },
  ];

  const columns: IColumns<IStudyRouteList> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: "20%",
        windowSize: windowWidth,
      }),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre", {
        searchState,
        setSearchState,
        width: "30%",
        windowSize: windowWidth,
      }),
    },
    {
      ...getDefaultColumnProps("departamento", "Departamento", {
        searchState,
        setSearchState,
        width: "25%",
        windowSize: windowWidth,
      }),
    },
    {
      ...getDefaultColumnProps("area", "Área", {
        searchState,
        setSearchState,
        width: "25%",
        windowSize: windowWidth,
      }),
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[], a: any, b: any) => {
    // setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelect: (record: any, selected: any, selectedRows: any) => {
      if (selected) {
        const newSelectedRowKeys = [...selectedRowKeys, record.id];
        setSelectedRowKeys(newSelectedRowKeys);
        if (
          !initialSelected.includes(record.id) &&
          !newSelected.includes(record.id)
        ) {
          setNewSelected((prev) => [...prev, record.id]);
        }
      } else {
        const newSelectedRowKeys = selectedRowKeys.filter(
          (x) => x !== record.id
        );
        setSelectedRowKeys(newSelectedRowKeys);
        if (newSelected.includes(record.id)) {
          setNewSelected((prev) => prev.filter((x) => x !== record.id));
        }
      }
    },
    onSelectAll: (selected: any, selectedRows: any) => {
      if (selected) {
        setSelectedRowKeys(selectedRows.map((x: any) => x.id));
        setNewSelected(
          selectedRows
            .filter((x: any) => !initialSelected.includes(x.id))
            .map((x: any) => x.id)
        );
      } else {
        setSelectedRowKeys([]);
        setNewSelected([]);
      }
    },
  };

  return (
    <Spin spinning={loading || printing} tip={printing ? "Imprimiendo" : ""}>
      <Row style={{ marginBottom: 24 }}>
        {!!id && (
          <Col md={12} sm={24} xs={12} style={{ textAlign: "left" }}>
            <Pagination
              simple
              key="pagination"
              size="small"
              total={routes?.length ?? 0}
              pageSize={1}
              current={getPage(id)}
              onChange={setPage}
              showSizeChanger={false}
            />
          </Col>
        )}
        {!readonly && scopes.modificar && scopes.crear && (
          <Col md={id ? 12 : 24} sm={24} xs={12} style={{ textAlign: "right" }}>
            <Button onClick={goBack}>Cancelar</Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                form.submit();
              }}
            >
              Guardar
            </Button>
          </Col>
        )}
        {readonly && scopes.modificar && (
          <Col md={12} sm={24} xs={12} style={{ textAlign: "right" }}>
            <ImageButton
              key="edit"
              title="Editar"
              image="editar"
              onClick={setEditMode}
            />
          </Col>
        )}
      </Row>

      <Form<IRouteForm>
        {...formItemLayout}
        form={form}
        name="reagent"
        initialValues={values}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row justify="space-between">
          <Col md={8} sm={24} xs={12}>
            <TextInput
              formProps={{
                name: "clave",
                label: "Clave",
              }}
              max={100}
              required
              readonly={readonly}
            />
          </Col>
          <Col md={8} sm={24} xs={12}>
            <SelectInput
              formProps={{
                name: "origenId",
                label: "Origen",
              }}
              readonly={readonly}
              required
              options={BranchOptions}
            />
          </Col>
          <Col md={8} sm={24} xs={12}>
            <Form.Item name="destinoId" label="Destino">
              <TreeSelect
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                treeData={treeData}
                placeholder="Seleccione un destino"
                treeDefaultExpandAll
                allowClear
              />
            </Form.Item>
          </Col>
          <Col md={8} sm={24} xs={12}>
            <TextInput
              formProps={{
                name: "nombre",
                label: "Nombre",
              }}
              max={100}
              required
              readonly={readonly}
            />
          </Col>
          <Col md={8} sm={24} xs={12}>
            <SelectInput
              formProps={{
                name: "paqueteriaId",
                label: "Paquetería ",
              }}
              placeholder="Sin paquetería"
              readonly={readonly}
              options={DeliveryOptions}
            />
          </Col>
          <Col md={8} sm={24} xs={12}>
            <Form.Item label="Aplica para días:" name="diasDeEntrega">
              <Space size={[12, 0]} wrap>
                {daysOfWeek.map((tag) => (
                  <CheckableTag
                    key={tag.id}
                    checked={
                      selectedTags.filter((x) => x.id === tag.id).length > 0
                    }
                    onChange={(checked) => handleChange(tag, checked)}
                  >
                    {tag.dia}
                  </CheckableTag>
                ))}
              </Space>
            </Form.Item>
          </Col>

          <Col md={8} sm={24} xs={12}>
            <TimeInput
              formProps={{
                name: "horaDeRecoleccion",
                label: "Hora de salida",
              }}
              readonly={readonly}
              required
            />
          </Col>

          <Col md={8} sm={24} xs={12}>
            <Form.Item label="Tiempo de Entrega" required>
              <Input.Group compact>
                <NumberInput
                  formProps={{
                    name: "tiempoDeEntrega",
                    label: "",
                  }}
                  min={1}
                  max={timeType === 2 ? 365 : 24}
                  readonly={readonly}
                />
                <SelectInput
                  formProps={{
                    name: "tipoTiempo",
                    label: "",
                  }}
                  defaultValue={1}
                  options={[
                    {
                      label: "Horas",
                      value: 1,
                    },
                    {
                      label: "Días",
                      value: 2,
                    },
                  ]}
                  readonly={readonly}
                />
              </Input.Group>
            </Form.Item>
          </Col>
          <Col md={8} sm={24} xs={12}>
            <SwitchInput
              name="activo"
              defaultChecked={true}
              onChange={(value) => {
                if (value) {
                  alerts.info(messages.confirmations.enable);
                } else {
                  alerts.info(messages.confirmations.disable);
                }
              }}
              label="Activo"
              readonly={readonly}
            />
          </Col>
          <Col md={8} sm={24} xs={12}>
            <TextAreaInput
              formProps={{
                name: "comentarios",
                label: "Comentarios",
              }}
              max={500}
              rows={4}
              autoSize
              readonly={readonly}
            />
          </Col>
        </Row>
      </Form>
      <Divider orientation="left">Asignación de Estudios</Divider>
      <Form
        {...formItemLayout}
        form={formAreaByDepartment}
        name="areaByDepartment"
      >
        <Row>
          <Col span={8}>
            <SelectInput
              options={departmentOptions}
              placeholder={"Departamentos"}
              formProps={{
                name: "departamentoId",
                label: "Departamento",
              }}
            />
          </Col>
          <Col span={8}>
            <SelectInput
              options={!departmentId ? [] : areaOptions}
              placeholder={"Área"}
              formProps={{
                name: "areaId",
                label: "Área",
              }}
            />
          </Col>
          <Col span={8}>
            <TextInput formProps={{ name: "buscar", label: "Buscar" }} />
          </Col>
        </Row>
      </Form>
      <br />
      <Row>
        <Col md={24} sm={24} xs={24}>
          <Table<IStudyRouteList>
            size="small"
            rowKey={(record) => record.id}
            columns={columns}
            pagination={false}
            dataSource={routeStudies.filter(
              (x) =>
                (x.departamentoId === departmentId || !departmentId) &&
                (x.areaId === areaId || !areaId) &&
                (x.clave.toLowerCase().includes(code?.toLowerCase()) ||
                  x.nombre.toLowerCase().includes(code?.toLowerCase()) ||
                  !code)
            )}
            rowSelection={rowSelection}
            components={VList({
              height: 500,
            })}
            scroll={{
              x: "max-content",
              y: 400,
            }}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default observer(RouteForm);
