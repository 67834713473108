import { Tabs, TabsProps } from "antd";
import { Fragment, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import PendingSendBody from "./TapsComponents/PendingSend/PendingSendBody";
import PendingReceiveBody from "./TapsComponents/PendingReceive/PendingReceiveBody";
import TrackingHistoryBody from "./TapsComponents/History/TrackingHistoryBody";
import { useStore } from "../../app/stores/store";

type RTDefaultProps = {
  printing: boolean;
};

const RouteTrackingTable = ({ printing }: RTDefaultProps) => {
  const [activeTab, setActiveTab] = useState("1");
  const { optionStore, profileStore, routeTrackingStore } = useStore();
  const { getRouteBranchByCityOptions } = optionStore;
  const { setCurrentTrackingTab } = routeTrackingStore;
  const { profile } = profileStore;

  useEffect(() => {
    getRouteBranchByCityOptions();
  }, [getRouteBranchByCityOptions, profile]);

  const handleTabChange = (key: string) => {
    setCurrentTrackingTab(key);
    setActiveTab(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Pendientes de enviar",
      children: <PendingSendBody printing={printing} activeTab={activeTab} />,
    },
    {
      key: "2",
      label: "Pendientes de recibir",
      children: (
        <PendingReceiveBody printing={printing} activeTab={activeTab} />
      ),
    },
    {
      key: "3",
      label: "Historial",
      children: <TrackingHistoryBody activeTab={activeTab} />,
    },
  ];

  return (
    <Fragment>
      <Fragment>
        <Tabs activeKey={activeTab} onChange={handleTabChange} items={items} />
      </Fragment>
    </Fragment>
  );
};

export default observer(RouteTrackingTable);
