import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../app/stores/store";
import { useSearchParams } from "react-router-dom";
interface Estudios {
  id: number;
  tipo: string;
  orden: number;
  isPatologia: boolean;
}

type Estudio = {
  EstudioId: string;
  Tipo: string;
  IsDepartmentPat: boolean;
};

type Solicitud = {
  SolicitudId: string;
  EstudiosId: Estudio[];
};

type DeliverResultsStudiesDto = {
  Estudios: Solicitud[];
};
const Consent = () => {
  const { profileStore, clinicResultsStore } = useStore();
  const { getPdfConsent } = profileStore;
  const { printStudiesQR, printResultFilePreview } = clinicResultsStore;
  const [pdf, setPdf] = useState<string>("");
  const [searchParams] = useSearchParams();

  const getPdf = async () => {
    const queryParams = searchParams.get("info");
    const queryParamsI = searchParams.get("infoI");

    if (!!queryParamsI) {
      const solicitudes: Solicitud[] = [];

      // Divide la información por '|'
      const solicitudParts = queryParamsI.split("|").filter((part) => part); // elimina las cadenas vacías

      let currentSolicitud: Solicitud | null = null;

      for (const part of solicitudParts) {
        if (!part.includes(",")) {
          // Si no contiene ',', es un nuevo `SolicitudId`
          if (currentSolicitud) {
            // Si ya existe una solicitud en curso, agrégala a la lista
            solicitudes.push(currentSolicitud);
          }

          // Crea una nueva solicitud
          currentSolicitud = {
            SolicitudId: part,
            EstudiosId: [],
          };
        } else {
          // Si contiene ',', es un `Estudio`
          const [EstudioId, Tipo, IsDepartmentPatStr] = part.split(",");
          const estudio: Estudio = {
            EstudioId,
            Tipo,
            IsDepartmentPat: !!+IsDepartmentPatStr, // Convierte a boolean
          };

          currentSolicitud?.EstudiosId.push(estudio);
        }
      }

      // Asegúrate de agregar la última solicitud
      if (currentSolicitud) {
        solicitudes.push(currentSolicitud);
      }

      let information = { Estudios: solicitudes, mediosEnvio: ["Logos"] };
      const pdf = await printResultFilePreview(information);

      setPdf(pdf ?? "");
      return;
    }
    if (!!queryParams) {
      console.log("searchParams", searchParams.get("info"));
      console.log("searchParams", searchParams.get("infoI"));

      const info = queryParams.split("|");

      let configuration = {
        sucursalId: info[0],
        solicitudId: info[1],
        imprimirCriticos: !!+info[2],
        imprimirPrevios: !!+info[3],
        imprimirLogos: true,
        estudios: [] as Estudios[],
      };

      for (let i = 4; i < info.length; i++) {
        const studyInfo = info[i].split(",");
        configuration.estudios.push({
          id: +studyInfo[0],
          tipo: studyInfo[1] === "P" ? "PATHOLOGICAL" : "LABORATORY",
          orden: +studyInfo[2],
          isPatologia: !!+studyInfo[1],
        });
      }

      const pdf = await printStudiesQR(configuration);
      setPdf(pdf ?? "");
      return;
    } else {
      getPdfConsent().then((pdf) => setPdf(pdf ?? ""));
      setPdf("");
      return;
    }
  };

  useEffect(() => {
    getPdf();
  }, [searchParams]);

  // useEffect(() => {
  //   getPdfConsent().then((pdf) => setPdf(pdf ?? ""));
  //   setPdf("");
  // }, []);

  useEffect(() => {
    if (pdf) {
      window.location.href = pdf; // Esto iniciará la descarga del PDF
    }
  }, [pdf]);
  return (
    <>
      {/* <iframe
          src={pdf}
          style={{ width: "100%", height: "100%" }}
          frameBorder="0"
        >
          alt: <a href={pdf}>test.pdf</a>
        </iframe> */}
      <p>Descargando el archivo...</p>
    </>
  );
};

export default observer(Consent);
