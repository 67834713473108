import { Checkbox, Col, Form, InputNumber, Row, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { VList } from "virtual-table-ant-design";
import {
  IColumns,
  ISearch,
  getDefaultColumnProps,
} from "../../../../app/common/table/utils";
import SelectInput from "../../../../app/common/form/proposal/SelectInput";
import TextInput from "../../../../app/common/form/proposal/TextInput";
import { formItemLayout } from "../../../../app/util/utils";
import { useStore } from "../../../../app/stores/store";
import { IPriceListStudy } from "../../../../app/models/priceList";
import { observer } from "mobx-react-lite";

type Props = {
  studies: IPriceListStudy[];
  setStudies: (studies: IPriceListStudy[]) => void;
  readonly: boolean;
};

const PriceListFormStudies = ({ studies, setStudies, readonly }: Props) => {
  const { optionStore, priceListStore } = useStore();
  const {
    areaOptions,
    departmentOptions,
    getAreaOptions,
    getDepartmentOptions,
  } = optionStore;
  const { updatePackPrices } = priceListStore;

  const [filterForm] = Form.useForm();

  const departmentId = Form.useWatch("departamentoId", filterForm);
  const areaId = Form.useWatch("areaId", filterForm);
  const code = Form.useWatch("clave", filterForm);

  const [filteredStudies, setFilteredStudies] = useState<any[]>([]);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  useEffect(() => {
    getDepartmentOptions();
  }, [getDepartmentOptions]);

  useEffect(() => {
    if (departmentId) getAreaOptions(departmentId);
  }, [departmentId, getAreaOptions]);

  useEffect(() => {
    const filtered = studies.filter(
      (x) =>
        (x.departamentoId === departmentId || !departmentId) &&
        (x.areaId === areaId || !areaId) &&
        (x.clave.toLowerCase().indexOf(code?.toLowerCase()) > -1 ||
          x.nombre.toLowerCase().indexOf(code?.toLowerCase()) > -1 ||
          !code)
    );
    setFilteredStudies(filtered);
  }, [areaId, code, departmentId, studies]);

  const changePrice = (item: IPriceListStudy, price: number) => {
    const index = studies.findIndex((x) => x.estudioId === item.estudioId);

    if (index === -1 || price == null) return;

    const priceStudies = [...studies];
    const study = priceStudies[index];
    study.precio = price;
    priceStudies[index] = study;

    setStudies(priceStudies);
    updatePackPrices(study);
  };

  const onChangeActive = (estudioId: number, active: boolean) => {
    const estudios = [...studies];
    const index = estudios.findIndex((x) => x.estudioId === estudioId);
    if (index === -1) return;

    estudios[index].activo = active;
    setStudies(estudios);
  };

  const columns: IColumns<IPriceListStudy> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: 80,
      }),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre", {
        searchState,
        setSearchState,
        width: 200,
      }),
    },
    {
      ...getDefaultColumnProps("departamento", "Departamento", {
        searchState,
        setSearchState,
        width: 100,
      }),
    },
    {
      ...getDefaultColumnProps("area", "Área", {
        searchState,
        setSearchState,
        width: 100,
      }),
    },
    {
      ...getDefaultColumnProps("precio", "Precio", {
        searchState,
        setSearchState,
        width: 100,
      }),
      align: "center",
      render: (value, item) => (
        <InputNumber
          type={"number"}
          min={0}
          max={99999}
          value={value}
          onChange={(value) => changePrice(item, value)}
          disabled={readonly}
        ></InputNumber>
      ),
    },
    {
      ...getDefaultColumnProps("activo", "Activo", {
        searchState,
        setSearchState,
        width: 30,
      }),
      align: "center",
      render: (value, record: IPriceListStudy) => {
        return (
          <Checkbox
            checked={value}
            onChange={(e) => {
              onChangeActive(record.estudioId, e.target.checked);
            }}
          ></Checkbox>
        );
      },
    },
  ];

  const virtual = useMemo(() => {
    return VList({
      height: 500,
    });
  }, []);

  return (
    <div>
      <Form
        style={{ marginBottom: 12 }}
        {...formItemLayout}
        form={filterForm}
        name="filter"
      >
        <Row>
          <Col span={8}>
            <SelectInput
              options={departmentOptions}
              placeholder={"Departamentos"}
              formProps={{
                name: "departamentoId",
                label: "Departamento",
              }}
            />
          </Col>
          <Col span={8}>
            <SelectInput
              options={!departmentId ? [] : areaOptions}
              placeholder={"Área"}
              formProps={{
                name: "areaId",
                label: "Área",
              }}
            />
          </Col>
          <Col span={8}>
            <TextInput formProps={{ name: "clave", label: "Buscar" }} />
          </Col>
        </Row>
      </Form>
      <Table<IPriceListStudy>
        rowKey={(record) => record.estudioId}
        columns={columns}
        pagination={false}
        dataSource={filteredStudies}
        components={virtual}
        scroll={{
          x: "100%",
          y: 500,
        }}
      />
    </div>
  );
};

export default observer(PriceListFormStudies);
