import { Form, Row, Col, Checkbox, Input, Button, Space } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import SelectInput from "../../../../app/common/form/proposal/SelectInput";
import SelectTagInput from "../../../../app/common/form/proposal/SelectTagInput";
import TextAreaInput from "../../../../app/common/form/proposal/TextAreaInput";
import TextInput from "../../../../app/common/form/proposal/TextInput";
import { IRequestGeneral } from "../../../../app/models/request";
import { IFormError, IOptions } from "../../../../app/models/shared";
import {
  originOptions,
  urgencyOptions,
} from "../../../../app/stores/optionStore";
import { useStore } from "../../../../app/stores/store";
import alerts from "../../../../app/util/alerts";
import { catalog, status } from "../../../../app/util/catalogs";
import { validateEmail } from "../../../../app/util/utils";
import RequestDeliveryHistory from "../RequestDeliveryHistory";
import { createMedic } from "../../../medics/utils/create";
import { toJS } from "mobx";

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 10 },
};

const PARTICULAR = 2;

const sendOptions = [
  { label: "Mandar vía correo electronico", value: "correo" },
  { label: "Mandar vía Whatsapp", value: "whatsapp" },
  { label: "Ambos", value: "ambos" },
];

type RequestGeneralProps = {
  form: FormInstance<IRequestGeneral>;
  onSubmit: (general: IRequestGeneral, showLoader: boolean) => Promise<void>;
};

const RequestGeneral = ({ form, onSubmit }: RequestGeneralProps) => {
  const { optionStore, requestStore } = useStore();
  const {
    companyOptions,
    medicOptions,
    getCompanyOptions,
    getMedicOptions,
    addMedicOption,
  } = optionStore;
  const {
    scopes,
    readonly,
    request,
    activePayments,
    allActiveStudies,
    hasStudies,
    setStudyFilter,
    getGeneral,
    sendTestEmail,
    sendTestWhatsapp,
    refreshPromo,
    studyFilter,
    getStudies,
  } = requestStore;

  const sendings = Form.useWatch("metodoEnvio", form);
  const doctorId = Form.useWatch("medicoId", form);
  const companyId = Form.useWatch("compañiaId", form);

  const emails = Form.useWatch("correos", form);
  const whatsapps = Form.useWatch("whatsapps", form);
  const sendToMedic = Form.useWatch("envioMedico", form);

  const [errors, setErrors] = useState<IFormError[]>([]);
  const [previousSendings, setPreviousSendings] = useState<string[]>([]);
  const [requestGeneral, setRequestGeneral] = useState<IRequestGeneral>();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidWhatsapp, setIsValidWhatsapp] = useState(false);
  const [selectedMedic, setSelectedMedic] = useState<IOptions>();

  useEffect(() => {
    if (companyOptions.length === 0) getCompanyOptions();
    if (medicOptions.length === 0) getMedicOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStudyFilter(request?.sucursalId, doctorId, companyId);
  }, [companyId, doctorId, request?.sucursalId, setStudyFilter]);

  useEffect(() => {
    const getRequestGeneral = async () => {
      const requestGeneral = await getGeneral(
        request!.expedienteId,
        request!.solicitudId!
      );
      if (requestGeneral) {
        setRequestGeneral(requestGeneral);
        form.setFieldsValue(requestGeneral);
      }
    };

    if (request && request.solicitudId && request.expedienteId) {
      getRequestGeneral();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  useEffect(() => {
    setIsValidEmail(
      !!emails && emails.length > 0 && emails.every(validateEmail)
    );
    setIsValidWhatsapp(
      !!whatsapps &&
        whatsapps.length > 0 &&
        whatsapps.every(
          (whatsapp) =>
            (whatsapp ?? "").replaceAll("-", "").replaceAll("_", "").length ===
            10
        )
    );
  }, [emails, whatsapps]);

  useEffect(() => {
    if (sendToMedic) {
      const medicId = form.getFieldValue("medicoId");
      if (medicId) {
        const medic = medicOptions.find((x) => x.value === medicId);
        form.setFieldsValue({
          correoMedico: medic?.extra?.correo ?? "Sin correo registrado",
          telefonoMedico: medic?.extra?.celular ?? "Sin celular registrado",
        });
        setSelectedMedic(medic);
      } else {
        setSelectedMedic(undefined);
      }
    } else {
      setSelectedMedic(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendToMedic, doctorId]);

  const onValuesChange = (changedValues: any) => {
    const path = Object.keys(changedValues)[0];

    if (path === "metodoEnvio") {
      const sendings: string[] = changedValues[path];
      let metodoEnvio: string[] = [];

      if (previousSendings.includes("ambos") && !sendings.includes("ambos")) {
        metodoEnvio = [];
        form.setFieldsValue({ correos: [], whatsapps: [] });
      } else if (
        !previousSendings.includes("ambos") &&
        sendings.includes("ambos")
      ) {
        metodoEnvio = ["correo", "whatsapp", "ambos"];
      } else if (sendings.length === 2 && !sendings.includes("ambos")) {
        metodoEnvio = ["correo", "whatsapp", "ambos"];
      } else {
        metodoEnvio = sendings.filter((x) => x !== "ambos");
      }

      if (!sendings.includes("correo")) {
        form.setFieldsValue({ correos: [] });
      }
      if (!sendings.includes("whatsapp")) {
        form.setFieldsValue({ whatsapps: [] });
      }

      form.setFieldsValue({ metodoEnvio });
      setPreviousSendings(metodoEnvio);
    }
  };

  const onFinish = (values: IRequestGeneral) => {
    setErrors([]);
    const request = { ...requestGeneral, ...values };
    request.correo = values.correos?.join(",");
    request.whatsapp = values.whatsapps?.join(",");

    let guardadoAutomatico = form.getFieldValue("guardadoAutomatico");

    if (requestGeneral?.compañiaId !== request.compañiaId && hasStudies) {
      alerts.confirm(
        "Hubo un cambio de compañía",
        "Con el cambio de Compañía, los estudios actualizarán sus precios, ¿Desea continuar?",
        async () => {
          request.cambioCompañia = true;
          await submit(request);
          await getStudies(request.expedienteId, request.solicitudId);
        },
        async () => {
          form.setFieldsValue(requestGeneral!);
        }
      );
      return;
    }

    request.cambioCompañia = false;
    request.guardadoAutomatico = guardadoAutomatico;
    submit(request);
  };

  const submit = async (request: IRequestGeneral) => {
    setRequestGeneral((prev) => ({ ...prev!, compañiaId: request.compañiaId }));
    const autoSave = form.getFieldValue("guardadoAutomatico");
    await onSubmit(request, autoSave);
  };

  const sendEmail = async () => {
    if (request && emails) {
      await sendTestEmail(request.expedienteId, request.solicitudId!, emails);
    }
  };

  const sendWhatsapp = async () => {
    if (request && whatsapps) {
      await sendTestWhatsapp(
        request.expedienteId,
        request.solicitudId!,
        whatsapps
      );
    }
  };

  const addMedic = async () => {
    const newMedic = await createMedic("request/medic");
    if (newMedic) {
      addMedicOption(newMedic);
      form.setFieldValue("medicoId", newMedic.idMedico);
      form.submit();
    }
  };

  const onChangeMedic = () => {
    studyFilter.medicoId = form.getFieldValue("medicoId");
    if (!activePayments.length) {
      refreshPromo();
    }
  };
  const onCompanyChange = (_value: string, option: IOptions | IOptions[]) => {
    form.setFieldValue("procedencia", (option as IOptions).group);
  };

  return (
    <Space direction="vertical" size={"large"}>
      <Form<IRequestGeneral>
        {...formItemLayout}
        form={form}
        onFinish={onFinish}
        onFinishFailed={({ errorFields }) => {
          const errors = errorFields.map((x) => ({
            name: x.name[0].toString(),
            errors: x.errors,
          }));
          setErrors(errors);
        }}
        initialValues={{
          metodoEnvio: [],
          companyId: catalog.company.particulares,
          procedencia: PARTICULAR,
          correos: [],
          whatsapps: [],
        }}
        onValuesChange={onValuesChange}
        size="small"
      >
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <SelectInput
              formProps={{
                name: "compañiaId",
                label: "Compañía",
                tooltip:
                  activePayments.length > 0 ||
                  allActiveStudies.some(
                    (x) =>
                      x.estatusId !== status.requestStudy.pendiente &&
                      x.estatusId !== status.requestStudy.cancelado
                  )
                    ? "No es posible cambiar la compañía cuando se han registrado pagos o los estudios no están en estatus pendiete"
                    : undefined,
              }}
              options={companyOptions}
              required
              errors={errors.find((x) => x.name === "compañiaId")?.errors}
              onChange={onCompanyChange}
              readonly={
                activePayments.length > 0 ||
                // allActiveStudies.some(
                //   (x) =>
                //     x.estatusId !== status.requestStudy.pendiente &&
                //     x.estatusId !== status.requestStudy.cancelado
                // ) ||
                readonly
              }
            />
          </Col>
          <Col span={24}>
            <SelectInput
              formProps={{
                name: "procedencia",
                label: "Procedencia",
              }}
              options={originOptions}
              readonly
              required
            />
          </Col>
          <Col span={24}>
            <Form.Item
              label="Médico"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              className="no-error-text"
              help=""
              required
            >
              <Input.Group>
                <Row>
                  <Col span={12}>
                    <SelectInput
                      formProps={{
                        name: "medicoId",
                      }}
                      options={medicOptions}
                      required
                      // readonly={readonly || activePayments.length > 0}
                      readonly={readonly}
                      onChange={onChangeMedic}
                    />
                  </Col>
                  <Col span={12}>
                    {!readonly && activePayments.length === 0 && (
                      <Button
                        type="primary"
                        disabled={readonly}
                        onClick={addMedic}
                      >
                        Nuevo médico
                      </Button>
                    )}
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <TextInput
              formProps={{
                name: "afiliacion",
                label: "Afiliación",
              }}
              max={100}
              readonly={readonly}
            />
          </Col>
          <Col span={24}>
            <SelectInput
              formProps={{
                name: "urgencia",
                label: "Urgencia",
              }}
              options={urgencyOptions}
              required
              errors={errors.find((x) => x.name === "urgencia")?.errors}
              readonly={readonly}
            />
          </Col>
          <Col span={24} style={{ textAlign: "start" }}>
            <Form.Item
              noStyle
              name="metodoEnvio"
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
            >
              <Checkbox.Group
                className={readonly ? "unclickable" : ""}
                options={sendOptions}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="E-Mail"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              className="no-error-text"
              help=""
              required={sendings?.includes("correo")}
            >
              <Input.Group>
                <Row>
                  <Col span={12}>
                    <SelectTagInput
                      formProps={{
                        name: "correos",
                        label: "E-Mail",
                        noStyle: true,
                      }}
                      regex={
                        /^([A-Za-z0-9_.-]+)@([\dA-Za-z.-]+)\.([A-Za-z.]{2,6})$/
                      }
                      readonly={!sendings?.includes("correo") || readonly}
                      required={sendings?.includes("correo")}
                      errors={errors.find((x) => x.name === "correos")?.errors}
                    />
                  </Col>
                  <Col span={12}>
                    {scopes.enviarCorreo &&
                      request?.estatusId === status.request.vigente && (
                        <Button
                          type="primary"
                          disabled={
                            !sendings?.includes("correo") || !isValidEmail
                          }
                          onClick={sendEmail}
                        >
                          Prueba
                        </Button>
                      )}
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Whatsapp"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              className="no-error-text"
              help=""
              required={sendings?.includes("whatsapp")}
            >
              <Input.Group>
                <Row>
                  <Col span={12}>
                    <SelectTagInput
                      formProps={{
                        name: "whatsapps",
                        label: "Whatsapp",
                        noStyle: true,
                      }}
                      regex={/^([0-9]{3})-?([0-9]{3})-?([0-9]{2})-?([0-9]{2})$/}
                      readonly={!sendings?.includes("whatsapp") || readonly}
                      required={sendings?.includes("whatsapp")}
                      errors={
                        errors.find((x) => x.name === "whatsapps")?.errors
                      }
                    />
                  </Col>
                  <Col span={12}>
                    {scopes.enviarWapp &&
                      request?.estatusId === status.request.vigente && (
                        <Button
                          type="primary"
                          disabled={
                            !sendings?.includes("whatsapp") || !isValidWhatsapp
                          }
                          onClick={sendWhatsapp}
                        >
                          Prueba
                        </Button>
                      )}
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "start" }}>
            <Form.Item
              noStyle
              name="envioMedico"
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
              valuePropName="checked"
            >
              <Checkbox className={readonly ? "unclickable" : ""}>
                Enviar resultados a médico
              </Checkbox>
            </Form.Item>
          </Col>
          {selectedMedic && (
            <Col span={24}>
              <TextInput
                formProps={{
                  name: "correoMedico",
                  label: "Correo médico",
                }}
                readonly
              />
            </Col>
          )}
          {selectedMedic && (
            <Col span={24}>
              <TextInput
                formProps={{
                  name: "telefonoMedico",
                  label: "Télefono médico",
                }}
                readonly
              />
            </Col>
          )}
          <Col span={24}>
            <TextAreaInput
              formProps={{
                name: "observaciones",
                label: "Observaciones",
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
              }}
              rows={3}
              errors={errors.find((x) => x.name === "observaciones")?.errors}
              readonly={readonly}
            />
          </Col>
        </Row>
      </Form>
      <RequestDeliveryHistory />
    </Space>
  );
};

export default observer(RequestGeneral);
