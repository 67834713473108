import { Button, Col, Input, Row, Spin, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { VList } from "virtual-table-ant-design";

import {
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../../app/common/table/utils";
import { ICompanyForm, ICompanyList } from "../../../../app/models/company";
import { useStore } from "../../../../app/stores/store";
import alerts from "../../../../app/util/alerts";
import useWindowDimensions from "../../../../app/util/window";

type Props = {
  getResult: (response: ICompanyForm | undefined) => void;
};

const InvoiceFreeSearchCompany = ({ getResult }: Props) => {
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });
  const { width: windowWidth } = useWindowDimensions();
  const { companyStore } = useStore();
  const { getActive, getById } = companyStore;

  const columns: IColumns<ICompanyList> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: "25%",
        minWidth: 150,
        windowSize: windowWidth,
      }),
    },
    {
      ...getDefaultColumnProps("nombreComercial", "Nombre", {
        searchState,
        setSearchState,
        width: "55%",
        minWidth: 150,
        windowSize: windowWidth,
      }),
    },
    {
      ...getDefaultColumnProps("procedencia", "Procedencia", {
        searchState,
        setSearchState,
        width: "20%",
        minWidth: 150,
        windowSize: windowWidth,
      }),
    },
  ];

  const [companies, setCompanies] = useState<ICompanyList[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<ICompanyList[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    const loadCompanies = async () => {
      setLoading(true);
      const companies = await getActive();
      setCompanies(companies);
      setFilteredCompanies(companies);
      setLoading(false);
    };

    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const select = async () => {
    const id = selectedRowKeys[0].toString();
    const selectedCompany = await getById(id);

    if (!selectedCompany || missingTaxData(selectedCompany)) {
      return;
    }

    getResult(selectedCompany);
  };

  const missingTaxData = (company: ICompanyForm) => {
    if (
      !company.rfc ||
      !company.razonSocial ||
      !company.regimenFiscal ||
      !company.codigoPostal
    ) {
      alerts.warning(
        "La compañía seleccionada no tiene todos los datos fiscales requeridos (RFC, Razón social, Régimen fiscal, CP)"
      );
      return true;
    }
    return false;
  };

  const virtual = useMemo(() => {
    return VList({
      height: 500,
    });
  }, []);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
    const filteredData = companies.filter(
      (company: ICompanyList | any) =>
        company.nombreComercial.toLowerCase().includes(value.toLowerCase()) ||
        company.clave.toLowerCase().includes(value.toLowerCase()) ||
        company.procedencia.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCompanies(filteredData);
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[12, 12]}>
        <Col span={24} style={{ textAlign: "right", marginBottom: "1rem" }}>
          <Input
            placeholder="Buscar compañía"
            value={searchText}
            onChange={handleSearch}
            style={{ width: 300 }}
          />
        </Col>
        <Col span={24}>
          <Table
            rowKey={(row) => row.id}
            columns={columns}
            // dataSource={companies}
            dataSource={filteredCompanies}
            bordered
            components={virtual}
            pagination={false}
            scroll={{
              x: "100%",
              y: 500,
            }}
            rowSelection={{
              type: "radio",
              selectedRowKeys: selectedRowKeys,
              onChange: async (newSelectedRowKeys: React.Key[]) => {
                setSelectedRowKeys(newSelectedRowKeys);
              },
            }}
          />
        </Col>
        <Col span={24} style={{ textAlign: "end" }}>
          <Button
            type="primary"
            onClick={select}
            disabled={selectedRowKeys.length === 0}
          >
            Aceptar
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

export default observer(InvoiceFreeSearchCompany);
