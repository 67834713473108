import { Button, Table, Tag } from "antd";
import { ExpandableConfig, SorterResult } from "antd/lib/table/interface";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import ExpandAll from "../../app/common/table/ExpandAll";
import {
  defaultPaginationProperties,
  IColumns,
} from "../../app/common/table/utils";
import {
  InvoiceData,
  IReportData,
  StudyStatisticsSummaryData,
  SummaryBranchAllData,
  SummaryBranchData,
} from "../../app/models/report";
import { useStore } from "../../app/stores/store";
import getInvoiceDataColumns from "./columnDefinition/invoiceData";
import getsummaryAllData, {
  getsummaryBranchData,
} from "./columnDefinition/summaryBranchData";

type ReportTableProps = {
  loading: boolean;
  data: IReportData[];
  columns: IColumns<IReportData>;
  hasFooterRow?: boolean;
  expandable?: ExpandableConfig<IReportData> | undefined;
  summary?: boolean;
  summaryByStudies?: boolean;
  summaryByBranch?: boolean;
};

let totalEstudios = 0;
let totalDescuentos = 0;
let total = 0;
let IVA = total * 0.16;
let subtotal = total - IVA;
let auxTotalDescuentosPorcentual = 0;
let totalDescuentosPorcentual = 0;

const ReportTable = ({
  loading,
  data,
  columns,
  hasFooterRow,
  expandable,
  summary,
  summaryByStudies,
  summaryByBranch,
}: ReportTableProps) => {
  const [report, setReport] = useState<string>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [openRows, setOpenRows] = useState<boolean>(false);
  const [params] = useSearchParams();
  const { reportStore, optionStore } = useStore();
  const { filter, setTableMetadata } = reportStore;
  const { branchCityOptions } = optionStore;
  useEffect(() => {
    setReport(params.get("report") ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.get("report")]);

  useEffect(() => {
    setExpandedRowKeys(data.map((x) => x.id));
    setOpenRows(true);
  }, [data]);

  totalDescuentos = 0;
  totalEstudios = 0;
  data.forEach((x) => {
    totalEstudios += x.precioEstudios;
    report === "cargo"
      ? (totalDescuentos += x.cargo)
      : (totalDescuentos += x.descuento);
  });
  auxTotalDescuentosPorcentual = (totalDescuentos / totalEstudios) * 100;
  totalDescuentosPorcentual =
    Math.round(auxTotalDescuentosPorcentual * 100) / 100;
  const total = totalEstudios - totalDescuentos;
  const subtotal = +(total / 1.16).toFixed(2);
  const IVA = +(total - subtotal).toFixed(2);

  const getInvoiceData = (report: string) => {
    let dataInvoice: InvoiceData[] = [];
    if (
      report === "facturas" ||
      report === "facturas_canceladas" ||
      report === "facturas_consaldo"
    ) {
      dataInvoice = [
        {
          key: "1",
          totalEstudios: 0,
          totalDescuentoPorcentual: 0,
          totalDescuentos: 0,
          subtotal: data.reduce((total, obj) => total + obj.subtotal, 0),
          iva: data.reduce((total, obj) => total + obj.iva, 0),
          total: data.reduce((total, obj) => total + obj.total, 0),
          noFacturas: data.length,
        },
      ];
      if (report === "facturas_consaldo")
        dataInvoice[0].saldo = data.reduce(
          (total, obj) => total + obj.saldo,
          0
        );
    } else {
      dataInvoice = [
        {
          key: "1",
          totalEstudios: totalEstudios === 0 ? 0 : totalEstudios,
          totalDescuentoPorcentual: isNaN(totalDescuentosPorcentual)
            ? 0
            : totalDescuentosPorcentual,
          totalDescuentos: totalDescuentos,
          subtotal: Math.round(subtotal * 100) / 100,
          iva: Math.round(IVA * 100) / 100,
          total: total,
        },
      ];
    }

    return dataInvoice;
  };

  const getStudyStatisticsSummaryData = () => {
    let totalTotal = data.reduce((total, obj) => total + obj.total, 0);
    let urgenteTotal = data.reduce((urgente, obj) => urgente + obj.urgente, 0);
    let normalTotal = data.reduce((normal, obj) => normal + obj.normal, 0);

    return {
      totalTotal,
      urgenteTotal,
      normalTotal,
    } as StudyStatisticsSummaryData;
  };

  const toggleRow = () => {
    if (openRows) {
      setOpenRows(false);
      setExpandedRowKeys([]);
    } else {
      setOpenRows(true);
      setExpandedRowKeys(data.map((x) => x.id));
    }
  };

  const allExpanded = expandedRowKeys.length === data.length;

  const onExpandAll = () => {
    if (allExpanded) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys(data.map((x) => x.id));
    }
  };

  const getAllSummary = () => {
    var totalAll: SummaryBranchAllData = {
      subtotal: data.reduce((total, obj) => total + obj.subtotal, 0),
      iva: data.reduce((total, obj) => total + obj.iva, 0),
      totalDescuentos: 0,
    };
    var descuento =
      (data.reduce((total, obj) => total + obj.descuento, 0) * 100) /
      data.reduce((total, obj) => total + obj.precioEstudios, 0);
    totalAll.totalDescuentos = parseFloat(descuento.toFixed(2));
    return totalAll;
  };

  const getBranchSummary = (list: IReportData[]) => {
    var total: SummaryBranchData = {
      totalPacientes: list.length,
      totalEstudios: list.reduce((total, obj) => total + obj.precioEstudios, 0),
      totalDescuentoPorcentual: 0,
      totalDescuentos: list.reduce((total, obj) => total + obj.descuento, 0),
      total: list.reduce((total, obj) => total + obj.totalEstudios, 0),
    };
    if (total.totalDescuentos > 0) {
      var descuento =
        (list.reduce((total, obj) => total + obj.descuento, 0) * 100) /
        list.reduce((total, obj) => total + obj.precioEstudios, 0);
      total.totalDescuentoPorcentual = parseFloat(descuento.toFixed(2));
    }
    return total;
  };

  const onExpandedRowsChange = (expandedRows: readonly React.Key[]) => {
    setExpandedRowKeys(expandedRows as string[]);
  };

  useEffect(() => {
    setExpandedRowKeys(data.map((x) => x.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  const totalRowStyle = {
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  };

  return (
    <Fragment>
      {data.length > 0 &&
        (report === "cargo" ||
          report === "presupuestos" ||
          report === "empresa" ||
          report === "medicos-desglosado" ||
          report === "maquila_interna" ||
          report === "maquila_externa" ||
          report === "canceladas") && (
          <div style={{ textAlign: "right", marginBottom: 10 }}>
            <Button
              type="primary"
              onClick={toggleRow}
              style={{ marginRight: 10 }}
            >
              {!openRows ? "Abrir tabla" : "Cerrar tabla"}
            </Button>
          </div>
        )}
      <Table<IReportData>
        onChange={(pagination, filters, sorter) => {
          console.log("hola", sorter);
          if (Array.isArray(sorter) && sorter.length > 0) {
            const primarySorter = sorter[0];
            setTableMetadata(
              primarySorter?.columnKey?.toString() ?? "",
              primarySorter?.order === "ascend" ? "asc" : "desc"
            );
          } else {
            const primarySorter = sorter as SorterResult<IReportData>;
            setTableMetadata(
              primarySorter?.columnKey?.toString() ?? "",
              primarySorter?.order === "ascend" ? "asc" : "desc"
            );
          }
        }}
        loading={loading}
        size="small"
        rowKey={(record) => record.id}
        columns={columns}
        pagination={defaultPaginationProperties}
        dataSource={[...data.filter((item) => item.claveMedico !== "Total")]}
        sticky
        scroll={{ x: "fit-content" }}
        rowClassName={(item) =>
          item.claveMedico === "Total" || item.paciente === "Total"
            ? "Resumen Total"
            : ""
        }
        expandable={{
          ...expandable,
          onExpandedRowsChange: onExpandedRowsChange,
          expandedRowKeys: expandedRowKeys,
          columnTitle:
            report === "urgentes" ? (
              <ExpandAll allExpanded={allExpanded} onExpandAll={onExpandAll} />
            ) : undefined,
        }}
        className="ReportTable"
        summary={() => {
          if (data.some((x) => x.claveMedico === "Total")) {
            const totalData = data.find((item) => item.claveMedico === "Total");
            return (
              <Table.Summary.Row style={totalRowStyle}>
                <Table.Summary.Cell index={0} colSpan={2}>
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  ${totalData?.total?.toLocaleString("es-MX")}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {totalData?.noPacientes}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  {totalData?.noSolicitudes}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          } else {
            return <></>;
          }
        }}
      />
      <div style={{ textAlign: "right", marginTop: 10, marginBottom: 10 }}>
        <Tag color="lime">
          {!hasFooterRow ? data.length : Math.max(data.length - 1, 0)} Registros
        </Tag>
      </div>
      {summary ? (
        <>
          <Table
            columns={getInvoiceDataColumns(report as string)}
            dataSource={getInvoiceData(report as string)}
            pagination={false}
          />
        </>
      ) : (
        ""
      )}
      {summaryByStudies ? (
        <>
          <Table<StudyStatisticsSummaryData>
            columns={getInvoiceDataColumns(report as string)}
            dataSource={[getStudyStatisticsSummaryData()]}
            pagination={false}
          />
        </>
      ) : (
        ""
      )}
      {summaryByBranch ? (
        <>
          {branchCityOptions
            .filter((o) => o.options != null)
            .map((o) => {
              return o.options![0].value;
            })
            .map((IdSucursal) => {
              if (
                filter.sucursalId?.length === 0 ||
                (filter.sucursalId?.length > 1 &&
                  filter.sucursalId?.findIndex((o) => o === IdSucursal) > -1)
              ) {
                var list = data.filter((o) => o.sucursalId === IdSucursal);
                return (
                  <div key={IdSucursal}>
                    <label>
                      <b>
                        TOTAL{" "}
                        {branchCityOptions
                          .find((x) =>
                            x.options!.some((y) => y.value === IdSucursal)
                          )
                          ?.label?.toString()
                          ?.toUpperCase()}
                      </b>
                    </label>
                    <Table
                      columns={getsummaryBranchData()}
                      dataSource={[getBranchSummary(list)]}
                      pagination={false}
                    />
                    <br></br>
                  </div>
                );
              }
              return "";
            })}
          <label>
            <b>GRAN TOTAL</b>
          </label>
          <Table
            columns={getsummaryBranchData()}
            dataSource={[getBranchSummary(data)]}
            pagination={false}
          />
          <br></br>
          <label>
            <b>TOTALES DE TODAS LAS SOLICITUDES</b>
          </label>
          <Table
            columns={getsummaryAllData()}
            dataSource={[getAllSummary()]}
            pagination={false}
          />
        </>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default observer(ReportTable);
